import firebase from "gatsby-plugin-firebase"
import * as nGram from "n-gram"
import { ICSVHeader, IRegisterResponse } from "./medicalDao"
import lodash, { forEach } from "lodash"
import dot from "dot-object"
import moment from "moment"

const proxyUrl = process.env.PROXY_URL
const appUrl = process.env.APP_URL
const funcUrl = process.env.FIREBASE_FUNCTION_URL
const auroraMedicalId = process.env.AURORA_MEDICAL_ID

export const generateActionCodeSettings = (email: string) => {
  const func_url = funcUrl
  return {
    url: `${proxyUrl}/?url=${appUrl}&func_url=${func_url}&email=${email}`,
    handleCodeInApp: true,
  }
}

export interface IWorkTime {
  no_work: boolean
  shift: boolean[]
}

export interface IFirestoreUser {
  id?: string
  type: number
  name: string
  name_kana: string
  gender: number
  tel: string
  search: any
  disabled: boolean
  push: boolean
  reminder: boolean
  sound: boolean
  vibration: boolean
  completed: boolean
  description?: string
  mon?: IWorkTime
  tue?: IWorkTime
  wed?: IWorkTime
  thu?: IWorkTime
  fri?: IWorkTime
  sat?: IWorkTime
  sun?: IWorkTime
  medical_id?: string
  department_id?: string
  created_at?: any
  updated_at?: any
}

export interface IRegisterUser {
  to_functions: ICreateUserWithSendMail
  medical_information?: {
    medical_id: string
    department_id: string | undefined
    description: string | undefined
    in_hospital_prescription: boolean
    sort_no: number | undefined
  }
}

export interface ICreateUserWithSendMail {
  user_data: IFirestoreUser,
  auth: {
    email: string
    password: string
    edit_login: boolean
    disabled: boolean
  },
  actionCodeSettings: any
}

export interface IExportUser {
  id: string
  company_id: string
  mail: string
  name: string
  name_kana: string
  gender: string
  birth_date: string
  post_code: string
  prefecture: string
  address1: string
  address2: string
  tel: string
  my_pharmacy: string
  credit: string
  medicine_note: string
  created_at: string
  disabled: string
  firstname_alpha: string
  lastname_alpha: string
  mileage_no: string
  mileage_registration_date: string
  coupon: string
  ponta_no: string
  ponta_registration_date: string
  nanaco_no: string
  nanaco_registration_date: string
  subscription_id: string
  subscription_status: string
  subscription_plan: string
  current_period_start: string
  current_period_end: string
  stripe_subscription_history: string
  award_select: string
  // families: string
  sokuyaku_user: string
  aurora_user: string
  bene_user: string
}

export interface IExportPointAward {
  id: string
  name: string
  name_kana: string
  gender: string
  birth_date: string
  post_code: string
  prefecture: string
  address1: string
  address2: string
  tel: string
  created_at: string
  disabled: string
  firstname_alpha: string
  lastname_alpha: string
  mileage_no: string
  mileage_registration_date: string
  ponta_no: string
  ponta_registration_date: string
  nanaco_no: string
  nanaco_registration_date: string
  award_select: string
  subscription: string
  subscription_id: string
  subscription_status: string
  subscription_plan: string
  current_period_start: string
  current_period_end: string
}

export interface IExportDoctor {
  id: string
  mail: string
  medical_id: string
  madical_name: string
  name: string
  name_kana:  string
  created_at: string
  type: string
  for_web:  string
  for_web_type: string
}

export const DoctorExportCSVHeader: ICSVHeader[] = [
  { type: "string", label: "id", key: "id", comment: "" },
  { type: "string", label: "mail", key: "mail", comment: "" },
  { type: "string", label: "medical_id", key: "medical_id", comment: "" },
  { type: "string", label: "madical_name", key: "madical_name", comment: "" },
  { type: "string", label: "name", key: "name", comment: "" },
  { type: "string", label: "name_kana", key: "name_kana", comment: "" },
  { type: "string", label: "created_at", key: "created_at", comment: "" },
  { type: "string", label: "type", key: "type", comment: "" },
  { type: "string", label: "for_web", key: "for_web", comment: "" },
  { type: "string", label: "for_web_type", key: "for_web_type", comment: "" },
]

export const PointAwardExportCSVHeader: ICSVHeader[] = [
  { type: "string", label: "ID", key: "id", comment: "" },
  { type: "string", label: "氏名", key: "name", comment: "" },
  { type: "string", label: "氏名カナ", key: "name_kana", comment: "" },
  { type: "string", label: "性別", key: "gender", comment: "「1」：男性「2」：女性" },
  { type: "string", label: "生年月日", key: "birth_date", comment: "" },
  { type: "string", label: "郵便番号", key: "post_code", comment: "" },
  { type: "string", label: "都道府県", key: "prefecture", comment: "" },
  { type: "string", label: "住所", key: "address1", comment: "" },
  { type: "string", label: "それ以降の住所", key: "address2", comment: "" },
  { type: "string", label: "電話番号", key: "tel", comment: "電話番号をハイフン無し" },
  { type: "string", label: "作成日", key: "created_at", comment: "作成日" },
  { type: "string", label: "退会済", key: "disabled", comment: "退会済" },
  { type: "string", label: "姓(アルファベット)", key: "firstname_alpha", comment: "" },
  { type: "string", label: "名(アルファベット)", key: "lastname_alpha", comment: "" },
  { type: "string", label: "JMB番号", key: "mileage_no", comment: "" },
  { type: "string", label: "JMB番号登録日時", key: "mileage_registration_date", comment: "" },
  { type: "string", label: "PONTA", key: "ponta_no", comment: "" },
  { type: "string", label: "PONTA登録日時", key: "ponta_registration_date", comment: "" },
  { type: "string", label: "NANACO", key: "nanaco_no", comment: "" },
  { type: "string", label: "NANACO登録日時", key: "nanaco_registration_date", comment: "" },
  { type: "string", label: "特典付与希望先", key: "award_select", comment: "" },
  { type: "string", label: "サブスク契約中", key: "subscription", comment: "" },
  { type: "string", label: "サブスクID", key: "subscription_id", comment: "" },
  { type: "string", label: "サブスクステータス", key: "subscription_status", comment: "" },
  { type: "string", label: "サブスクプラン", key: "subscription_plan", comment: "" },
  { type: "string", label: "サブスク開始日", key: "current_period_start", comment: "" },
  { type: "string", label: "サブスク終了日", key: "current_period_end", comment: "" },
]

export const UserExportCSVHeader: ICSVHeader[] = [
  { type: "string", label: "ID", key: "id", comment: "" },
  { type: "string", label: "氏名", key: "name", comment: "" },
  { type: "string", label: "氏名カナ", key: "name_kana", comment: "" },
  { type: "string", label: "性別", key: "gender", comment: "「1」：男性「2」：女性" },
  { type: "string", label: "生年月日", key: "birth_date", comment: "" },
  { type: "string", label: "郵便番号", key: "post_code", comment: "" },
  { type: "string", label: "都道府県", key: "prefecture", comment: "" },
  { type: "string", label: "住所", key: "address1", comment: "" },
  { type: "string", label: "それ以降の住所", key: "address2", comment: "" },
  { type: "string", label: "メールアドレス", key: "mail", comment: "" },
  { type: "string", label: "電話番号", key: "tel", comment: "電話番号をハイフン無し" },
  { type: "string", label: "My薬局", key: "my_pharmacy", comment: "薬局名" },
  { type: "string", label: "クレカ登録", key: "credit", comment: "あり or なし" },
  { type: "string", label: "お薬手帳登録", key: "medicine_note", comment: "あり or なし" },
  { type: "string", label: "作成日", key: "created_at", comment: "作成日" },
  { type: "string", label: "退会済", key: "disabled", comment: "退会済" },
  { type: "string", label: "姓(アルファベット)", key: "firstname_alpha", comment: "" },
  { type: "string", label: "名(アルファベット)", key: "lastname_alpha", comment: "" },
  { type: "string", label: "JMB番号", key: "mileage_no", comment: "" },
  { type: "string", label: "JMB番号登録日時", key: "mileage_registration_date", comment: "" },
  { type: "string", label: "PONTA", key: "ponta_no", comment: "" },
  { type: "string", label: "PONTA登録日時", key: "ponta_registration_date", comment: "" },
  { type: "string", label: "NANACO", key: "nanaco_no", comment: "" },
  { type: "string", label: "NANACO登録日時", key: "nanaco_registration_date", comment: "" },
  { type: "string", label: "特典付与希望先", key: "award_select", comment: "" },
  { type: "string", label: "クーポンコード", key: "coupon", comment: "" },
  { type: "string", label: "サブスクID", key: "subscription_id", comment: "" },
  { type: "string", label: "サブスクステータス", key: "subscription_status", comment: "" },
  { type: "string", label: "サブスクプラン", key: "subscription_plan", comment: "" },
  { type: "string", label: "サブスク開始日", key: "current_period_start", comment: "" },
  { type: "string", label: "サブスク終了日", key: "current_period_end", comment: "" },
  { type: "string", label: "サブスク履歴", key: "stripe_subscription_history", comment: "" },
  { type: "string", label: "企業ID", key: "company_id", comment: "" },
  // { type: "string", label: "家族情報", key: "families", comment: "" },
  { type: "string", label: "SOKUYAKUユーザー", key: "sokuyaku_user", comment: "" },
  { type: "string", label: "オーロラクリニックユーザー", key: "aurora_user", comment: "" },
  { type: "string", label: "SOKUYAKUベネフィットユーザー", key: "bene_user", comment: "" },
]

export const UserCSVHeader: ICSVHeader[] = [
  { type: "boolean", label: "取り込み対象", key: "enable", comment: "「true」：SOKUYAKUに新規登録されます、空：行をスキップしてSOKUYAKUへ登録されません" },
  { type: "number", label: "タイプ", key: "type", comment: "「1」:クリニック管理者、「2」:医師、「3」:クリニック受付、「4」:薬局管理者、「5」:薬剤師、「6」:薬局受付" },
  { type: "string", label: "メール", key: "email", comment: "ログインで使用するメールアドレス" },
  { type: "string", label: "パスワード", key: "password", comment: "ログインで使用するパスワード" },
  { type: "string", label: "氏名", key: "name", comment: "" },
  { type: "string", label: "氏名カナ", key: "name_kana", comment: "" },
  { type: "number", label: "性別", key: "gender", comment: "「1」：男性「2」：女性" },
  { type: "string", label: "TEL", key: "tel", comment: "電話番号をハイフン無しで入力" },
  { type: "string", label: "医療機関名", key: "medical_name", comment: "" },
  { type: "string", label: "概要", key: "description", comment: "タイプが「2」と「5」(医師、薬剤師)の場合必須。それ以外のタイプの場合無視されます" },
  {
    type: "boolean",
    label: "月曜勤務",
    key: "mon.work",
    comment: "「true」：勤務有り、空：勤務なし　※タイプが「2」と「5」(医師、薬剤師)の場合必須。それ以外のタイプの場合無視されます"
  },
  {
    type: "number",
    label: "勤務開始時間（月）",
    key: "mon.start",
    comment: "0～47のいずれかの数字を入力。(00:00～23:30まで30分区切りで対応)　※タイプが「2」と「5」(医師、薬剤師)の場合必須。それ以外のタイプの場合無視されます"
  },
  {
    type: "number",
    label: "勤務終了時間（月）",
    key: "mon.end",
    comment: "0～47のいずれかの数字を入力。(00:00～23:30まで30分区切りで対応)　※タイプが「2」と「5」(医師、薬剤師)の場合必須。それ以外のタイプの場合無視されます"
  },
  {
    type: "boolean",
    label: "火曜勤務",
    key: "tue.work",
    comment: "「true」：勤務有り、空：勤務なし　※タイプが「2」と「5」(医師、薬剤師)の場合必須。それ以外のタイプの場合無視されます"
  },
  {
    type: "number",
    label: "勤務開始時間（火）",
    key: "tue.start",
    comment: "0～47のいずれかの数字を入力。(00:00～23:30まで30分区切りで対応)　※タイプが「2」と「5」(医師、薬剤師)の場合必須。それ以外のタイプの場合無視されます"
  },
  {
    type: "number",
    label: "勤務終了時間（火）",
    key: "tue.end",
    comment: "0～47のいずれかの数字を入力。(00:00～23:30まで30分区切りで対応)　※タイプが「2」と「5」(医師、薬剤師)の場合必須。それ以外のタイプの場合無視されます"
  },
  {
    type: "boolean",
    label: "水曜勤務",
    key: "wed.work",
    comment: "「true」：勤務有り、空：勤務なし　※タイプが「2」と「5」(医師、薬剤師)の場合必須。それ以外のタイプの場合無視されます"
  },
  {
    type: "number",
    label: "勤務開始時間（水）",
    key: "wed.start",
    comment: "0～47のいずれかの数字を入力。(00:00～23:30まで30分区切りで対応)　※タイプが「2」と「5」(医師、薬剤師)の場合必須。それ以外のタイプの場合無視されます"
  },
  {
    type: "number",
    label: "勤務終了時間（水）",
    key: "wed.end",
    comment: "0～47のいずれかの数字を入力。(00:00～23:30まで30分区切りで対応)　※タイプが「2」と「5」(医師、薬剤師)の場合必須。それ以外のタイプの場合無視されます"
  },
  {
    type: "boolean",
    label: "木曜勤務",
    key: "thu.work",
    comment: "「true」：勤務有り、空：勤務なし　※タイプが「2」と「5」(医師、薬剤師)の場合必須。それ以外のタイプの場合無視されます"
  },
  {
    type: "number",
    label: "勤務開始時間（木）",
    key: "thu.start",
    comment: "0～47のいずれかの数字を入力。(00:00～23:30まで30分区切りで対応)　※タイプが「2」と「5」(医師、薬剤師)の場合必須。それ以外のタイプの場合無視されます"
  },
  {
    type: "number",
    label: "勤務終了時間（木）",
    key: "thu.end",
    comment: "0～47のいずれかの数字を入力。(00:00～23:30まで30分区切りで対応)　※タイプが「2」と「5」(医師、薬剤師)の場合必須。それ以外のタイプの場合無視されます"
  },
  {
    type: "boolean",
    label: "金曜勤務",
    key: "fri.work",
    comment: "「true」：勤務有り、空：勤務なし　※タイプが「2」と「5」(医師、薬剤師)の場合必須。それ以外のタイプの場合無視されます"
  },
  {
    type: "number",
    label: "勤務開始時間（金）",
    key: "fri.start",
    comment: "0～47のいずれかの数字を入力。(00:00～23:30まで30分区切りで対応)　※タイプが「2」と「5」(医師、薬剤師)の場合必須。それ以外のタイプの場合無視されます"
  },
  {
    type: "number",
    label: "勤務終了時間（金）",
    key: "fri.end",
    comment: "0～47のいずれかの数字を入力。(00:00～23:30まで30分区切りで対応)　※タイプが「2」と「5」(医師、薬剤師)の場合必須。それ以外のタイプの場合無視されます"
  },
  {
    type: "boolean",
    label: "土曜勤務",
    key: "sat.work",
    comment: "「true」：勤務有り、空：勤務なし　※タイプが「2」と「5」(医師、薬剤師)の場合必須。それ以外のタイプの場合無視されます"
  },
  {
    type: "number",
    label: "勤務開始時間（土）",
    key: "sat.start",
    comment: "0～47のいずれかの数字を入力。(00:00～23:30まで30分区切りで対応)　※タイプが「2」と「5」(医師、薬剤師)の場合必須。それ以外のタイプの場合無視されます"
  },
  {
    type: "number",
    label: "勤務終了時間（土）",
    key: "sat.end",
    comment: "0～47のいずれかの数字を入力。(00:00～23:30まで30分区切りで対応)　※タイプが「2」と「5」(医師、薬剤師)の場合必須。それ以外のタイプの場合無視されます"
  },
  {
    type: "boolean",
    label: "日曜勤務",
    key: "sun.work",
    comment: "「true」：勤務有り、空：勤務なし　※タイプが「2」と「5」(医師、薬剤師)の場合必須。それ以外のタイプの場合無視されます"
  },
  {
    type: "number",
    label: "勤務開始時間（日）",
    key: "sun.start",
    comment: "0～47のいずれかの数字を入力。(00:00～23:30まで30分区切りで対応)　※タイプが「2」と「5」(医師、薬剤師)の場合必須。それ以外のタイプの場合無視されます"
  },
  {
    type: "number",
    label: "勤務終了時間（日）",
    key: "sun.end",
    comment: "0～47のいずれかの数字を入力。(00:00～23:30まで30分区切りで対応)　※タイプが「2」と「5」(医師、薬剤師)の場合必須。それ以外のタイプの場合無視されます"
  },
  { type: "string", label: "診療科名", key: "department.name", comment: "タイプが「2」(医師)の場合のみ入力可。入力がない場合、医師の診療科登録は実行されません" },
  { type: "string", label: "診療科の説明", key: "department.description", comment: "タイプが「2」(医師)の場合のみ入力可。入力がない場合、医師の診療科登録は実行されません" },
  { type: "boolean", label: "お薬院内処方", key: "department.in_hospital_prescription", comment: "「true」：院内処方、空：院外処方　※タイプが「2」(医師)の場合のみ入力可。入力がない場合、医師の診療科登録は実行されません" },
]

interface IWork {
  work: boolean
  start: number
  end: number
}

export interface IUserCSV {
  enable: boolean
  type: number
  email: string
  password: string
  name: string
  name_kana: string
  gender: number
  tel: string
  medical_name: string
  department: {
    name: string
    description: string
    in_hospital_prescription: boolean
  }
  description: string
  mon: IWork
  tue: IWork
  wed: IWork
  thu: IWork
  fri: IWork
  sat: IWork
  sun: IWork
}

export const convertUserCSVToJSON = (data: string[]) => {
  let retVal = {}
  data.forEach((value, index) => {
    const key = UserCSVHeader[index].key
    let tempVal = undefined
    if (UserCSVHeader[index].type === "boolean") {
      // 強制的にBoolean変換
      tempVal = !!value
    } else {
      tempVal = value
    }

    const obj = dot.object({ [key]: tempVal })
    lodash.merge(retVal, obj)
  })

  return retVal
}

export interface IConvertUserJsonToFBError {
  message: string
}

const convertRangeToShift = (start: number, end: number) => {
  if(start == null) {
    start = -1
  }
  if(end == null) {
    end = -1
  }
  return [ ...Array(48) ].map((_, index) => start <= index && index <= end)
}

export const convertUserJsonToFB = async (firestore: firebase.firestore.Firestore | undefined, data: IUserCSV): Promise<IRegisterUser | IConvertUserJsonToFBError> => {
  let retVal: IRegisterUser | IConvertUserJsonToFBError = {
    message: "スキップされました"
  }
  if (firestore) {
    try {
      if (data.enable) {
        let tempDescription = undefined
        let tempMon: undefined | IWorkTime = undefined
        let tempTue: undefined | IWorkTime = undefined
        let tempWed: undefined | IWorkTime = undefined
        let tempThu: undefined | IWorkTime = undefined
        let tempFri: undefined | IWorkTime = undefined
        let tempSat: undefined | IWorkTime = undefined
        let tempSun: undefined | IWorkTime = undefined
        let tempMedicalId = undefined
        let tempDepartmentId = undefined
        let tempDepartmentSortNo = undefined

        // 医療機関の存在チェック
        const medical = await firestore.collection("medical").where("name", "==", data.medical_name).get()
        if (medical.size) {
          tempMedicalId = medical.docs[0].id

          if (data.type === 2 || data.type === 5) {
            // 医師、または薬剤師の場合、シフトを変換
            tempMon = {
              no_work: !data.mon.work,
              shift: data.mon.work ? convertRangeToShift(data.mon.start, data.mon.end) : convertRangeToShift(-1, -1)
            }
            tempTue = {
              no_work: !data.tue.work,
              shift: data.tue.work ? convertRangeToShift(data.tue.start, data.tue.end) : convertRangeToShift(-1, -1)
            }
            tempWed = {
              no_work: !data.wed.work,
              shift: data.wed.work ? convertRangeToShift(data.wed.start, data.wed.end) : convertRangeToShift(-1, -1)
            }
            tempThu = {
              no_work: !data.thu.work,
              shift: data.thu.work ? convertRangeToShift(data.thu.start, data.thu.end) : convertRangeToShift(-1, -1)
            }
            tempFri = {
              no_work: !data.fri.work,
              shift: data.fri.work ? convertRangeToShift(data.fri.start, data.fri.end) : convertRangeToShift(-1, -1)
            }
            tempSat = {
              no_work: !data.sat.work,
              shift: data.sat.work ? convertRangeToShift(data.sat.start, data.sat.end) : convertRangeToShift(-1, -1)
            }
            tempSun = {
              no_work: !data.sun.work,
              shift: data.sun.work ? convertRangeToShift(data.sun.start, data.sun.end) : convertRangeToShift(-1, -1)
            }
            tempDescription = data.description
          }

          if (data.type === 2) {
            // 医師の場合、診療科の存在チェック
            const department = await firestore.collection("department").where("name", "==", data.department.name).get()
            if (department.size && department.docs[0].id) {
              tempDepartmentId = department.docs[0].id
              tempDepartmentSortNo = department.docs[0].data().sort_no
            } else {
              throw new Error("指定された診療科が存在しません")
            }
          }

          // 検索用に検索キーワードを登録
          let search: any[] = []
          search = search.concat(nGram.bigram(data.name))
          const searchMap: any = {}
          search.forEach(word => searchMap[word] = true)

          retVal = {
            to_functions: {
              user_data: {
                type: data.type,
                name: data.name,
                name_kana: data.name_kana,
                gender: data.gender,
                tel: data.tel,
                search: searchMap,
                disabled: false,
                push: true,
                reminder: true,
                sound: true,
                vibration: true,
                completed: true,
                description: tempDescription,
                medical_id: tempMedicalId,
                mon: tempMon,
                tue: tempTue,
                wed: tempWed,
                thu: tempThu,
                fri: tempFri,
                sat: tempSat,
                sun: tempSun
              },
              auth: {
                email: data.email,
                password: data.password,
                disabled: false,
                edit_login: false
              },
              actionCodeSettings: generateActionCodeSettings(data.email)
            },
            medical_information: {
              medical_id: tempMedicalId,
              department_id: tempDepartmentId,
              description: data.department?.description ? data.department.description : undefined,
              in_hospital_prescription: data.department?.in_hospital_prescription,
              sort_no: tempDepartmentSortNo
            }
          }
        } else {
          throw new Error("指定されている医療機関が存在しません")
        }
      }
    } catch (e) {
      retVal = {
        message: e.message
      }
    }
  } else {
    retVal = {
      message: "接続が中断されました。時間をおいてお試しください"
    }
  }

  return retVal
}

export const registerUser = (firestore:firebase.firestore.Firestore | undefined , functions: firebase.functions.Functions | undefined, data: Array<IRegisterUser | IConvertUserJsonToFBError>) => {
  if (firestore && functions) {

    console.log("firestore, functions",firestore, functions)

    const promiseArray = data.map((value, index) => {
      return async (): Promise<IRegisterResponse> => {
        let result = ""
        //正常　medicalへ情報登録
        if (value) {
          try {
            if ("message" in value && value.message) {
              // エラーとみなして処理をスキップ
              result = value.message
            } else {
              if ("to_functions" in value && value.to_functions) {
                const registerUserData : IRegisterUser = value
                const func = functions.httpsCallable("createUserFromCsv")
                await func(registerUserData).then(()=> {
                  result = "登録が完了しました"
                }).catch(e => {
                  console.log(e)
                  if(["invalid-argument","failed-precondition","permission-denied"].includes(e.code)){
                    result = e.toString()
                  }else{
                    result = "不明なエラーが発生しました。既にメールアドレスが使用されている可能性があります"
                  }
                })
              }
            }
          } catch (e) {
            console.log(e)
            result = "不明なエラーが発生しました。既にメールアドレスが使用されている可能性があります"
          }
        } else {
          result = "スキップされました"
        }

        return ({
          index: index + 2,
          result
        })
      }
    })

    const arrayResult: IRegisterResponse[] = []
    return promiseArray.reduce((prev, current) => {
      return prev.then(value => {
        arrayResult.push(value)
        return current()
      })
    }, Promise.resolve({
      index: 1,
      result: "先頭行はスキップされます"
    })).then((result) => {
      arrayResult.push(result)
      return arrayResult
    })
  } else {
    const temp: IRegisterResponse = {
      index: 0,
      result: "接続が中断されました。時間をおいてお試しください"
    }
    return Promise.resolve([temp])
  }
}

interface IAuthUser {
  id: string
  mail: string
  creationTime: string
}

type IStripeSubscriptionHistory = {
  subscription_id: string;
  subscription_plan: number;
  current_period_start: number;
  current_period_end: number;
}[];

export const AWARD = {
  ALL: 0,
  JAL: 1,
  PONTA: 2,
  NANACO: 3,
} as const
export type AwardType = typeof AWARD[keyof typeof AWARD]

const formatDate = (timestamp: number | undefined) => {
  //空の場合は""を返す
  if (!timestamp) {
    return "";
  }
  const date = moment(timestamp * 1000).format("YYYY/MM/DD HH:mm:ss")
  return date
};

const subscriptionHistoryDataShaping = (data: IStripeSubscriptionHistory) => {
  let ret = ""

  if (data) {
    ret = "サブスクリプションID,プラン,期間\n"

    if (data && Array.isArray(data)) {
      data.forEach((record) => {
        const subscriptionId = record.subscription_id
        const subscriptionPlan = record.subscription_plan === 1 ? "月額" : "年額"
        const currentPeriodStart = formatDate(record.current_period_start)
        const currentPeriodEnd = formatDate(record.current_period_end)

        ret += `${subscriptionId},${subscriptionPlan},${currentPeriodStart}～${currentPeriodEnd}\n` // データ行
      })
    }
  }
  return ret
}

export const getUserInformationByCSV = async (firestore: firebase.firestore.Firestore | undefined , functions: firebase.functions.Functions | undefined,secureInfo:boolean,medicineNoteInfo:boolean,dateInfo:boolean,dateFrom:Date | null,dateTo:Date | null,setProgressNum:any, mailInfo:boolean, myPharmacyInfo:boolean) => {
  let retVal: IExportUser[] = []
  try {
    if (firestore) {

      console.log("1")
      const pharmacyMaster: any = {}
      const medicalQuery = firestore.collection("medical")
      let medicalLastDoc = null;
      let hasNextPage = true;

      if (myPharmacyInfo) {
        while (hasNextPage) {
          // @ts-ignore
          const { docs, nextPageExists  } = await getDocByLimit(medicalQuery, medicalLastDoc, 1000);
          if (docs.length > 0) {
            forEach(docs, (doc) => {
              if(doc.data().type === 1)
                pharmacyMaster[doc.id] = {
                  name: doc.data().name
                }
            })
            // 最後のドキュメントを保持
            medicalLastDoc = docs[docs.length - 1];
          }

          // 次のページが存在するかどうかを更新
          hasNextPage = nextPageExists;
        }
      }

      console.log("2")
      let docsList : firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>[] = []
      const secureMaster: any = {}

      setProgressNum(1)

      console.log("3")
      if(secureInfo || medicineNoteInfo){

        const secureQuery = firestore.collection("secure")
        let secureLastDoc = null;
        hasNextPage = true;
        while (hasNextPage) {
          // @ts-ignore
          const { docs, nextPageExists  } = await getDocByLimit(secureQuery, secureLastDoc, 1000);
          if (docs.length > 0) {
            forEach(docs, (doc) => {
              docsList.push(doc)
            })
            // 最後のドキュメントを保持
            secureLastDoc = docs[docs.length - 1];
          }

          // 次のページが存在するかどうかを更新
          hasNextPage = nextPageExists;
        }

        if(secureInfo){
          for (let i = 0 ; i < docsList.length ; i++) {
            const doc = docsList[i]
            secureMaster[doc.id] = {
              credit: doc.data().stripe?.source ? "あり" : "なし",
              stripe_subscription: doc.data().stripe_subscription ?? null,
              stripe_subscription_history: doc.data().stripe_subscription_history ?? null,
            }
          }

        }

        if(medicineNoteInfo){
          let y = 0
          for (let i = 0 ; i < docsList.length ; i++) {
            const doc = docsList[i]
            await doc.ref.collection("medicine_note_list").get().then(value1 => {
              secureMaster[doc.id] = {
                ...secureMaster[doc.id],
                medicine_note: value1.docs.length > 0 ? "あり" : "なし"
              }
            })

            setProgressNum(1 + Math.round((i/docsList.length) * 94))

            console.log("i",i)
            if(y > 500){
              await new Promise(resolve => setTimeout(resolve, 3000))
              y = 0
            }
            y++
          }
        }
      }

      setProgressNum(95)

      //メールアドレス
      let mailList : IAuthUser[] = [{id: "",mail:"",creationTime:""}]
      if(mailInfo){
        if(functions){
          console.log("mail start")
          const func = functions.httpsCallable("getAuthUserList",{timeout : 550 * 1000})
          await func({from:dateInfo ? moment(dateFrom).format("YYYY/MM/DD") : undefined,to: dateInfo ? moment(dateTo).format("YYYY/MM/DD") : undefined}).then((data)=> {
            mailList = data.data
            console.log("mailList.length",mailList.length)
          }).catch(e => {
            console.log(e)
          })
          console.log("mail end")
        }
      }

      setProgressNum(99)

      const userQuery = firestore.collection("private")
      let userLastDoc = null;
      hasNextPage = true;

      while (hasNextPage) {
        // @ts-ignore
        const { docs, nextPageExists  } = await getDocByLimit(userQuery, userLastDoc, 1000);
        if (docs.length > 0) {
          forEach(docs, (result) => {
            const user = result.data()
            if(user.type === 7){
              const created_at = user?.created_at?.seconds ? moment(user.created_at.seconds * 1000).format("YYYY/MM/DD HH:mm:ss") : (user?.updated_at?.seconds ? moment(user.updated_at.seconds * 1000).format("YYYY/MM/DD HH:mm:ss") : "")
              // @ts-ignore
              let dateFlg
              const mail = mailList.find(v => v.id === result.id)
              if(mailInfo){
                dateFlg = (mail?.creationTime && moment(mail.creationTime).isAfter(moment(dateFrom).startOf("day")) && moment(mail.creationTime).isBefore(moment(dateTo).endOf("day")))
              }else{
                dateFlg = (created_at && moment(created_at).isAfter(moment(dateFrom).startOf("day")) && moment(created_at).isBefore(moment(dateTo).endOf("day")))
              }

              // // birth_dateを日付文字列に変換し、genderを文字列に変換する処理
              // let transformedFamilies = []
              // if (user?.families) {
              //   transformedFamilies = user.families.map((family: any) => {
              //     if(family.birth_date !== "NaN"){
              //       const date = family.birth_date !== "NaN" ? new Date(family.birth_date) : new Date();
              //       const formattedDate = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, "0")}-${String(date.getDate()).padStart(2, "0")}`;
              //       const genderString = family.gender === 1 ? "男性" : family.gender === 2 ? "女性" : "不明";
              //       return `名前: ${family.name},カナ: ${family.name_kana},生年月日: ${formattedDate},性別: ${genderString}`;
              //     }
              //   });
              // }

              if((!dateInfo || dateFlg)){
                const secureData = secureMaster[result.id]
                retVal.push({
                  id: result.id,
                  mail: mail?.mail ? mail.mail : "",
                  company_id: user?.company_id ? user.company_id : "",
                  name: user?.name ? user.name : "",
                  name_kana: user?.name_kana ? user.name_kana : "",
                  gender: user ? user.gender === 1 ? "男性" : "女性" : "",
                  birth_date: user?.birth_date ?　moment(user.birth_date).format("YYYY/MM/DD") : "",
                  post_code: user?.post_code ? user.post_code : "",
                  prefecture: user?.prefecture ? user.prefecture : "",
                  address1: user?.address1 ? user.address1 : "",
                  address2: user?.address2 ? user.address2 : "",
                  tel: user?.tel ? "t" + user.tel : "",
                  my_pharmacy: myPharmacyInfo ? (user?.my_pharmacy && pharmacyMaster[user.my_pharmacy]?.name ? pharmacyMaster[user.my_pharmacy].name : "") : user?.my_pharmacy,
                  credit: medicineNoteInfo ? secureData?.credit ? secureData.credit : "なし" : "",
                  medicine_note: medicineNoteInfo ? secureData?.medicine_note ? secureData.medicine_note : "なし" : "",
                  created_at: mail?.creationTime ? moment(mail.creationTime).format("YYYY/MM/DD HH:mm:ss") : created_at,
                  disabled: user.disabled ? "退会済" : "",
                  firstname_alpha: user?.firstname_alpha ? user.firstname_alpha : "",
                  lastname_alpha: user?.lastname_alpha ? user.lastname_alpha : "",
                  mileage_no: user?.mileage_no ? user.mileage_no : "",
                  mileage_registration_date: user?.mileage_registration_date ? moment(user.mileage_registration_date).format("YYYY/MM/DD") : "",
                  coupon: user?.coupon ? user.coupon : "",
                  ponta_no: user?.ponta_no ? "p" + user.ponta_no : "",
                  ponta_registration_date: user?.ponta_registration_date ? moment(user.ponta_registration_date).format("YYYY/MM/DD") : "",
                  nanaco_no: user?.nanaco_no ? "n" + user.nanaco_no : "",
                  nanaco_registration_date: user?.nanaco_registration_date ? moment(user.nanaco_registration_date).format("YYYY/MM/DD") : "",
                  subscription_id: secureData?.stripe_subscription?.subscription_id ? secureData.stripe_subscription.subscription_id : "",
                  subscription_status: secureData?.stripe_subscription?.subscription_status ? secureData.stripe_subscription.subscription_status : "",
                  subscription_plan: secureData?.stripe_subscription?.subscription_plan ? secureData.stripe_subscription.subscription_plan === 1 ? "月額" : "年額" : "",
                  current_period_start: formatDate(secureData?.stripe_subscription?.current_period_start),
                  current_period_end: formatDate(secureData?.stripe_subscription?.current_period_end),
                  stripe_subscription_history: subscriptionHistoryDataShaping(secureData?.stripe_subscription_history),
                  award_select: user?.award_select
                    ? user.award_select === AWARD.JAL
                      ? "JALマイル"
                      : user.award_select === AWARD.PONTA
                        ? "Ponta"
                        : user.award_select === AWARD.NANACO
                          ? "Nanaco"
                          : ""
                    : "",
                  // families: transformedFamilies.join("\n"),
                  sokuyaku_user: user?.registered_pharmacy_id !== auroraMedicalId ? "〇" : "",
                  aurora_user: user?.registered_pharmacy_id === auroraMedicalId  ? "〇" : "",
                  bene_user: user?.company_id ? "〇" : "",
                })
              }
            }

          })
          // 最後のドキュメントを保持
          userLastDoc = docs[docs.length - 1];
          console.log("userLastDoc",userLastDoc.id)
        }
        hasNextPage = nextPageExists;
      }

      setProgressNum(100)
    }
  } catch (e) {
    console.log(e)
  }

  return retVal
}

async function getDocByLimit(query: firebase.firestore.CollectionReference<firebase.firestore.DocumentData>, startAfterDoc: any, batchSize: number) {
  // startAfterDocを使用して次のページを取得
  let batchQuery = query.limit(batchSize);
  if (startAfterDoc) {
    batchQuery = batchQuery.startAfter(startAfterDoc);
  }

  const batchSnapshot = await batchQuery.get();
  const docs = batchSnapshot.docs;
  const nextPageExists = docs.length === batchSize;

  return { docs, nextPageExists };
}

export const getPointAwardInformationByCSV = async (firestore: firebase.firestore.Firestore | undefined , functions: firebase.functions.Functions | undefined,dateInfo:boolean,dateFrom:Date | null,dateTo:Date | null,setProgressNum:any, award: AwardType) => {
  let retVal: IExportPointAward[] = []
  try {
    if (firestore) {
      let hasNextPage = true;
      setProgressNum(1)
      let userQuery

      let col_name = ""
      switch (award) {
        case AWARD.ALL:
          break
        case AWARD.JAL:
          col_name = "mileage"
          break
        case AWARD.PONTA:
          col_name = "ponta"
          break
        case AWARD.NANACO:
          col_name = "nanaco"
          break
      }

      if (award === AWARD.ALL) {
        userQuery = firestore.collection("private")
      } else {
        if (dateInfo) {
          userQuery = firestore.collection("private").where("award_select","==", award)
            .where(col_name + "_registration_date","<=",dateTo?.valueOf())
            .where(col_name + "_registration_date",">=",dateFrom?.valueOf())
        } else {
          userQuery = firestore.collection("private").where("award_select","==", award)
        }
      }

      //サブスク情報取得
      let docsList : firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>[] = []
      const secureMaster: any = {}
      const secureQuery = firestore.collection("secure").where("stripe_subscription","!=",null)
      let secureLastDoc = null;
      hasNextPage = true;
      while (hasNextPage) {
        // @ts-ignore
        const { docs, nextPageExists } = await getDocByLimit(secureQuery, secureLastDoc, 1000);
        if (docs.length > 0) {
          forEach(docs, (doc) => {
            docsList.push(doc)
          })
          // 最後のドキュメントを保持
          secureLastDoc = docs[docs.length - 1];
        }

        // 次のページが存在するかどうかを更新
        hasNextPage = nextPageExists;
      }
      for (let i = 0; i < docsList.length; i++) {
        const doc = docsList[i]
        secureMaster[doc.id] = {
          credit: doc.data().stripe?.source ? "あり" : "なし",
          stripe_subscription: doc.data().stripe_subscription ?? null,
          stripe_subscription_history: doc.data().stripe_subscription_history ?? null,
        }
      }

      let userLastDoc = null;
      hasNextPage = true;
      while (hasNextPage) {
        // @ts-ignore
        const { docs, nextPageExists  } = await getDocByLimit(userQuery, userLastDoc, 1000);
        if (docs.length > 0) {
          forEach(docs, (result) => {
            const user = result.data()
            if(user.type === 7 && (user?.mileage_no || user?.ponta_no || user?.nanaco_no)){
              const created_at = user?.created_at?.seconds ? moment(user.created_at.seconds * 1000).format("YYYY/MM/DD HH:mm:ss") : (user?.updated_at?.seconds ? moment(user.updated_at.seconds * 1000).format("YYYY/MM/DD HH:mm:ss") : "")
              retVal.push({
                id: result.id,
                name: user?.name ? user.name : "",
                name_kana: user?.name_kana ? user.name_kana : "",
                gender: user ? user.gender === 1 ? "男性" : "女性" : "",
                birth_date: user?.birth_date ?　moment(user.birth_date).format("YYYY/MM/DD") : "",
                post_code: user?.post_code ? user.post_code : "",
                prefecture: user?.prefecture ? user.prefecture : "",
                address1: user?.address1 ? user.address1 : "",
                address2: user?.address2 ? user.address2 : "",
                tel: user?.tel ? "t" + user.tel : "",
                created_at: created_at,
                disabled: user.disabled ? "退会済" : "",
                firstname_alpha: user?.firstname_alpha ? user.firstname_alpha : "",
                lastname_alpha: user?.lastname_alpha ? user.lastname_alpha : "",
                mileage_no: user?.mileage_no ? user.mileage_no : "",
                mileage_registration_date: user?.mileage_registration_date ? moment(user.mileage_registration_date).format("YYYY/MM/DD") : "",
                ponta_no: user?.ponta_no ? "p" + user.ponta_no : "",
                ponta_registration_date: user?.ponta_registration_date ? moment(user.ponta_registration_date).format("YYYY/MM/DD") : "",
                nanaco_no: user?.nanaco_no ? "n" + user.nanaco_no : "",
                nanaco_registration_date: user?.nanaco_registration_date ? moment(user.nanaco_registration_date).format("YYYY/MM/DD") : "",
                award_select: user?.award_select
                  ? user.award_select === AWARD.JAL
                    ? "JALマイル"
                    : user.award_select === AWARD.PONTA
                      ? "Ponta"
                      : user.award_select === AWARD.NANACO
                        ? "Nanaco"
                        : ""
                  : "",
                subscription: secureMaster[result.id]?.stripe_subscription?.subscription_id ? subscriptionStatus(secureMaster[result.id].stripe_subscription) : "",
                subscription_id: secureMaster[result.id]?.stripe_subscription?.subscription_id ? secureMaster[result.id]?.stripe_subscription.subscription_id : "",
                subscription_status: secureMaster[result.id]?.stripe_subscription?.subscription_status ? secureMaster[result.id]?.stripe_subscription.subscription_status : "",
                subscription_plan: secureMaster[result.id]?.stripe_subscription?.subscription_plan ? secureMaster[result.id]?.stripe_subscription.subscription_plan === 1 ? "月額" : "年額" : "",
                current_period_start: formatDate(secureMaster[result.id]?.stripe_subscription?.current_period_start),
                current_period_end: formatDate(secureMaster[result.id]?.stripe_subscription?.current_period_end),
              })
            }

          })
          // 最後のドキュメントを保持
          userLastDoc = docs[docs.length - 1];
          console.log("userLastDoc",userLastDoc.id)
        }
        hasNextPage = nextPageExists;
      }

      setProgressNum(100)
    }
  } catch (e) {
    console.log(e)
  }

  return retVal
}

export const getDoctorInformationByCSV = async (firestore: firebase.firestore.Firestore | undefined , functions: firebase.functions.Functions | undefined ,setProgressNum:any ) => {
  let retVal: IExportDoctor[] = []
  try {
    if (firestore) {
      let hasNextPage = true;
      setProgressNum(1)

      const userQuery = firestore.collection("private")
      let userLastDoc = null;
      hasNextPage = true;

      while (hasNextPage) {
        // @ts-ignore
        const { docs, nextPageExists  } = await getDocByLimit(userQuery, userLastDoc, 1000);
        if (docs.length > 0) {
          forEach(docs, (result) => {
            const user = result.data()
            if(user.type === 4 || user.type === 6 || user.type === 5 || user.for_web_type === 5){
              console.log("user.type",user.type)
              const created_at = user?.created_at?.seconds ? moment(user.created_at.seconds * 1000).format("YYYY/MM/DD HH:mm:ss") : (user?.updated_at?.seconds ? moment(user.updated_at.seconds * 1000).format("YYYY/MM/DD HH:mm:ss") : "")
              retVal.push({
                id: result.id,
                mail: "",
                medical_id: user?.medical_id ? user.medical_id : "",
                madical_name: "",
                name: user?.name ? user.name : "",
                name_kana: user?.name_kana ? user.name_kana : "",
                created_at: created_at,
                type: user?.type ? user.type : "",
                for_web: user?.for_web ? user.for_web : "",
                for_web_type: user?.for_web_type ? user.for_web_type : "",
              })
            }

          })
          // 最後のドキュメントを保持
          userLastDoc = docs[docs.length - 1];
          console.log("userLastDoc",userLastDoc.id)
        }
        hasNextPage = nextPageExists;
      }

      setProgressNum(100)
    }
  } catch (e) {
    console.log(e)
  }

  return retVal
}

const subscriptionStatus = (data: any) => {

  if (data && data.subscription_id && data.current_period_end) {
    const currentPeriodEnd = moment(data.current_period_end * 1000)
    if (currentPeriodEnd.isAfter(moment())) {
      return `サブスク契約中`
    }
  }

  return ""
}
