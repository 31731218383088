
/**
 * 予約可能時間
 */
export const RESERVE_TIME = {
  "00:00": 0, "00:30": 1,
  "01:00": 2, "01:30": 3,
  "02:00": 4, "02:30": 5,
  "03:00": 6, "03:30": 7,
  "04:00": 8, "04:30": 9,
  "05:00": 10, "05:30": 11,
  "06:00": 12, "06:30": 13,
  "07:00": 14, "07:30": 15,
  "08:00": 16, "08:30": 17,
  "09:00": 18, "09:30": 19,
  "10:00": 20, "10:30": 21,
  "11:00": 22, "11:30": 23,
  "12:00": 24, "12:30": 25,
  "13:00": 26, "13:30": 27,
  "14:00": 28, "14:30": 29,
  "15:00": 30, "15:30": 31,
  "16:00": 32, "16:30": 33,
  "17:00": 34, "17:30": 35,
  "18:00": 36, "18:30": 37,
  "19:00": 38, "19:30": 39,
  "20:00": 40, "20:30": 41,
  "21:00": 42, "21:30": 43,
  "22:00": 44, "22:30": 45,
  "23:00": 46, "23:30": 47,
} as const
export type ReserveTimeType = typeof RESERVE_TIME[keyof typeof RESERVE_TIME]
export const ReserveTimeKey = (value: any) => {
  // @ts-ignore
  return Object.keys(RESERVE_TIME).find(key => RESERVE_TIME[key] === value)
}

/**
 * 表示時間
 */
export const DISPLAY_TIME = {
  "00:00": 0, "00:30": 1,
  "01:00": 2, "01:30": 3,
  "02:00": 4, "02:30": 5,
  "03:00": 6, "03:30": 7,
  "04:00": 8, "04:30": 9,
  "05:00": 10, "05:30": 11,
  "06:00": 12, "06:30": 13,
  "07:00": 14, "07:30": 15,
  "08:00": 16, "08:30": 17,
  "09:00": 18, "09:30": 19,
  "10:00": 20, "10:30": 21,
  "11:00": 22, "11:30": 23,
  "12:00": 24, "12:30": 25,
  "13:00": 26, "13:30": 27,
  "14:00": 28, "14:30": 29,
  "15:00": 30, "15:30": 31,
  "16:00": 32, "16:30": 33,
  "17:00": 34, "17:30": 35,
  "18:00": 36, "18:30": 37,
  "19:00": 38, "19:30": 39,
  "20:00": 40, "20:30": 41,
  "21:00": 42, "21:30": 43,
  "22:00": 44, "22:30": 45,
  "23:00": 46, "23:30": 47,
  "24:00": 48
} as const
export type DisplayTimeType = typeof DISPLAY_TIME[keyof typeof DISPLAY_TIME]
export const DisplayTimeKey = (value: DisplayTimeType | number) => {
// @ts-ignore
  return Object.keys(DISPLAY_TIME).find(key => DISPLAY_TIME[key] === value)
}

export const reserveTimeReplace = (
  reserveValue: any,
  reserveLimit: number = 1,
  count: number = 0
) => {
  const [hours, minutes] = reserveValue.split(':').map(Number);
  const calcNum = 30 / reserveLimit

  // 基準となる分数に計算した値を足す
  const newMinutesFrom = (minutes + (calcNum * count)) % 60;
  const newMinutesTo = (minutes + (calcNum * (count + 1))) % 60;

  // 60分を超えた場合は時間も繰り上げる
  const newHoursFrom = hours + Math.floor((minutes + (calcNum * count)) / 60);
  const newHoursTo = hours + Math.floor((minutes + (calcNum * (count + 1))) / 60);

  // フォーマットを整える
  const formattedMinutesFrom = ("0" + newMinutesFrom).slice(-2);
  const formattedHoursFrom = ("0" + newHoursFrom).slice(-2);
  const formattedMinutesTo = ("0" + newMinutesTo).slice(-2);
  const formattedHoursTo = ("0" + newHoursTo).slice(-2);

  return [`${formattedHoursFrom}:${formattedMinutesFrom}`, `${formattedHoursTo}:${formattedMinutesTo}`];
}

/**
 * パーミッション
 */
export const PERMISSION = {
  ADMINISTRATOR: 0,
  CORPORATION_ADMIN: 0.5,
  CLINIC_ADMIN: 1,
  DOCTOR: 2,
  CLINIC_DESK: 3,
  PHARMACY_ADMIN: 4,
  PHARMACIST: 5,
  PHARMACY_DESK: 6,
  CUSTOMER: 7,
} as const
export type PermissionType = typeof PERMISSION[keyof typeof PERMISSION]

/**
 * 機関タイプ
 */
export type INSTITUTION = {
  CLINIC: 0,
  PHARMACY: 1
}

/**
 * 送信範囲
 */
export const SEND_TYPE = {
  UNICAST: 0,
  MULTICAST: 1
}
export type SendType = typeof SEND_TYPE[keyof typeof SEND_TYPE]

/**
 * 性別定数
 */
export const GENDER = {
  MALE: 1,
  FEMALE: 2,
} as const
export type GenderType = typeof GENDER[keyof typeof GENDER]

/**
 * 予約進行状況
 */
export const RESERVE_STATUS = {
  EMPTY: 0,
  RESERVE: 1,
  WAITING: 2,
  DOCTOR_WAITING: 3,
  START: 4,
  END: 5,
  EXIT_ROOM: 70,
  CANCEL_BY_CUSTOMER: 80,
  CANCEL_BY_DOCTOR: 81,
  CANCEL_BY_SYSTEM: 82,
  PAID: 90,
  FAILED: 91
}
export type ReserveStatusType = typeof RESERVE_STATUS[keyof typeof RESERVE_STATUS]

/**
 * 診療タイプ
 */
export const RESERVE = {
  CLINIC_NOW: 0,
  CLINIC_RESERVE: 1,
  PHARMACY_RESERVE: 2,
  VISIT_RESERVE: 3,
  PERIOD_RESERVE: 11
}
export type ReserveType = typeof RESERVE[keyof typeof RESERVE]

/**
 * お薬配送方法
 */
export const MEDICINE_RECEIVE_TYPE = {
  MAIL: 0,
  RECEIPT: 1,
  BIKE: 2,
  JAPANPOST: 3,
  PUDO: 4,
  COUNTER: 10
} as const
export type MedicineReceiveType = typeof MEDICINE_RECEIVE_TYPE[keyof typeof MEDICINE_RECEIVE_TYPE]

/**
 * レビューステータス
 */
export const REVIEW_STATUS = {
  UNAUTHORIZED: 0,
  AUTHORIZED: 1,
  REJECT: 9
} as const
export type ReviewStatusType = typeof REVIEW_STATUS[keyof typeof REVIEW_STATUS]

/**
 * 支払方法ステータス
 */
export const PAYMENT_METHOD = {
  CREDIT_CARD: 0,
  PAY_AFTER: 1,
}
export type PaymentMethod = typeof PAYMENT_METHOD[keyof typeof PAYMENT_METHOD]

/**
 * 機関タイプ
 */
export const SUBSCRIPTION_PLAN = {
  MONTHLY: 1,
  YEARLY: 2,
  DAILY: 9,
}
export type SubscriptionPlanType = typeof SUBSCRIPTION_PLAN[keyof typeof SUBSCRIPTION_PLAN]

/**
 * 予約データ用マイナ保険証状態タイプ
 */
export const MY_NUMBER_STATUS = {
  NOT_YET: 1,
  DONE: 2,
  CHANGES_REQUIRED: 3
}
export type MyNumberStatusType = typeof MY_NUMBER_STATUS[keyof typeof MY_NUMBER_STATUS]

/**
 * クリニックレビューリクエスト
 */
export interface IReviewRequest {
  reserve_id: string
  medical_id: string
  score: number
  status?: ReviewStatusType
  detail?: string
  admin_comment?: string
}

/**
 * クリニックレビュー
 */
export interface IReview extends IReviewRequest {
  id: string
  status: ReviewStatusType
  created_at: any
  updated_at: any
}

/**
 * 統計情報表示用予約取得リクエスト
 */
export interface IReserveForAnalyticsRequest {
  from: number
  to: number
}

/**
 * 統計情報表示用予約取得レスポンス
 */
export interface IReserveForAnalyticsResponse {
  summary: {
    reserve_count: number
    total_amount: number
  }
  scale: {
    time: {
      [hhmm: string]: {
        reserve_count: number
        total_amount: number
      }
    }
    date: {
      [yyyymmdd: string]: {
        reserve_count: number
        total_amount: number
      }
    }
  }
  status: {
    reserved: number
    ended: number
    paid: number
    canceled: number
    failed: number
  }
}

/**
 * 日付指定直近予約可能リクエスト
 */
export interface IMedicalSearchRequest {
  type: InstitutionType
  search?: string
  prefecture?: string
  department?: string
  rate_to?: number
  rate_from?: number
  date?: number
  from?: number
  to?: number
  first_visit?: boolean
  geo?: {
    range: number
    latitude: number
    longitude: number
  }
  page?: number
  japanpost?: boolean
  sunday?: boolean
}

/**
 * 日付指定直近予約可能リクエスト
 */
export interface IVisitPharmacySearchRequest {
  type: InstitutionType
  search?: string
  prefecture?: string
  department?: string
  rate_to?: number
  rate_from?: number
  date?: number
  from?: number
  to?: number
  first_visit?: boolean
  geo?: {
    range: number
    latitude: number
    longitude: number
  }
  page?: number
  today?: boolean
}

/**
 * 勤務時間オブジェクト
 */
export interface IWorkRange {
  no_work: boolean
  shift: boolean[]
}

/**
 * クリニックで対応している診療科情報
 */
export interface IClinicDepartment {
  id: string
  doctors: Array<string>
  description: string,
  sort_no?: number,
  in_hospital_prescription?: boolean,
}

export interface IDayOfWeek {
  mon: boolean,
  tue: boolean,
  wed: boolean,
  thu: boolean,
  fri: boolean,
  sat: boolean,
  sun: boolean,
}

/**
 * chat情報
 */
export interface IMessage {
  created_at: number
  from: string
  message: string
}

/**
 * クリニックレコード
 */
export interface IClinicRecord {
  id?: string,
  store_id?: string,
  departments: IClinicDepartment[],
  rate: number,
  type: InstitutionType,
  name: string,
  tel?: string,
  fax?: string,
  url?: string,
  tags?: string[],
  post_code: string,
  prefecture: string,
  address1: string,
  address2: string,
  description?: string,
  warning?: string,
  main_image?: string,
  start: ReserveTimeType,
  end: DisplayTimeType,
  day_of_week: IDayOfWeek,
  images?: string[],
  search?: any,
  g?: any,
  coordinates?: any,
  today_reservable_count?: number
  first_visit?: boolean
  re_visit?: boolean
  caminova_id?: string
  bank_name?: string
  bank_branch_name?: string
  bank_account_type?: string
  bank_branch_code?: string
  bank_account_number?: string
  bank_account_holder?: string
  delivery_company?: string
  pickup_time?: string
  created_at?: any
  updated_at?: any
  in_hospital_prescription?: boolean
  send_fax?: boolean
  holidays?: any
}

/**
 * 検索
 */
export interface ISearch {
  id: string
  clinics: Array<string>
}

/**
 * ユーザー共通
 */
export interface IUserCommon {
  id: string
  type?: PermissionType
  name?: string
  name_kana?: string
  gender?: GenderType
  tel?: string
  medical_id?: string
  department_id?: string
  description?: string
  search?: any
  birth_date?: number
  disabled?: boolean
  updated_at?: any
  post_code?: string
  prefecture?: string
  address1?: string
  address2?: string
  created_at?: number
  for_web_type?: PermissionType
  for_aurora_type?: PermissionType
  sub_department_id?: string[]
  mfa?: boolean
  corp?: string
  verified_at?: any
  corporation_id?: string
  company_id?: string
  coupon?: string
  used_friend_coupon_clinic?: any
  used_friend_coupon_pharmacy?: any
  mileage_no?: string
  mileage_registration_date?: number
  ponta_no?: string
  ponta_registration_date?: number
  my_pharmacy?: string
}

/**
 * 勤務時間
 */
export interface IWorkRange {
  no_work: boolean,
  shift: boolean[]
}

export interface IAvailableMedical {
  type: InstitutionType
  department?: string,
  limit?: number
}

/**
 * 医師
 */
export interface IDoctor {
  id: string
  type?: PermissionType
  for_web?: boolean
  for_web_type?: PermissionType
  for_aurora?: boolean
  for_aurora_type?: PermissionType
  name?: string
  name_kana?: string
  gender?: GenderType
  tel?: string
  medical_id?: string
  department_id: string,
  description?: string
  search?: any
  birth_date?: number
  mon: IWorkRange,
  tue: IWorkRange,
  wed: IWorkRange,
  thu: IWorkRange,
  fri: IWorkRange,
  sat: IWorkRange,
  sun: IWorkRange,
  sub_department_id?: string[],
}

/**
 * 薬剤師
 */
export interface IPharmacist {
  id: string
  type?: PermissionType
  name?: string
  name_kana?: string
  gender?: GenderType
  tel?: string
  medical_id?: string
  description?: string
  search?: any
  birth_date?: number
  mon: IWorkRange,
  tue: IWorkRange,
  wed: IWorkRange,
  thu: IWorkRange,
  fri: IWorkRange,
  sat: IWorkRange,
  sun: IWorkRange
}

/**
 * セキュア情報
 */
export interface ISecure {
  id: string
  push_token?: string
  device_id?: string | boolean
  insurance_card_front?: string
  insurance_card_back?: string
  stripe?: {
    customer_id?: string
    source?: string
  }
  stripe_for_aurora?: {
    customer_id?: string
    source?: string
  }
  stripe_subscription?: {
    subscription_id?: string
    subscription_status?: string
    subscription_plan?: number
    current_period_start?: number
    current_period_end?: number
  }
  stripe_subscription_history?: {
    subscription_id?: string
    subscription_plan?: number
    current_period_start?: number
    current_period_end?: number
  }[]
  created_at?: any
  updated_at?: any
}

export interface INotification {
  id: string
  to: string | PermissionType
  type: SendType
  message: string
  is_sent: boolean
}

export interface IMonthSchedule {
  [day: string]: IDaySchedule
}

/**
 * 日毎のスケジュール
 */
export interface IDaySchedule {
  [doctorId: string]: {
    [reserveTime: string]: boolean | string
  }
}

/**
 * 予約取得リクエスト
 */
export interface IGetReserve {
  reserve_id: string
}

export interface IPudo {
  id: string
  address: string
  name: string
  post_code: string
  prefecture: string
  latitude: number
  longitude: number
}

export interface IDeliveryAddress {
  post_code: string
  prefecture: string
  address1: string
  address2: string
}

/**
 * 患者予約リクエスト
 */
export interface ICustomerReserveRequest {
  clinic: {
    date: number
    department_id: string
    doctor_id: string
    first: boolean
    from: ReserveTimeType
    interviews: any[]
    medical_id: string
    type: ReserveType
    images?: string[]
    medicine_receive_type: number
    in_hospital_prescription: boolean
    payment_method?: number
    pudo_shop?: any
    delivery_address?: IDeliveryAddress
    coupon?: string
    time_diff?: number
    reserve_limit?: number
    is_pet?: boolean
    pet_type?: string
    my_number_status?: number
  }
  pharmacy: {
    date: number
    doctor_id: string
    from: ReserveTimeType
    interviews: any[]
    medical_id: string
    type: ReserveType
    medicine_receive_type: number
    patient_id?: string
    payment_method?: number
    pudo_shop?: any
    delivery_address?: IDeliveryAddress
    coupon?: string
    time_diff?: number
    reserve_limit?: number
    is_pet?: boolean
    pet_type?: string
    my_number_status?: number
  }
  family_no?:any
}

/**
 * 患者予約リクエスト
 */
export interface IVisitCustomerReserveRequest {
  visit: {
    date: number
    department_id: string
    doctor_id: string
    first: boolean
    from: ReserveTimeType
    interviews: any[]
    medical_id: string
    type: ReserveType
    images?: string[]
    prescription_images?: string[]
    medicine_receive_type: number
    has_prescription?: boolean
  }
}

/**
 * 薬局予約リクエスト
 */
export interface IPharmacyReserveRequest {
  clinic_reserve_id: string
  clinic: any
  pharmacy: {
    date: number
    doctor_id: string
    from: ReserveTimeType
    interviews: any[]
    medical_id: string
    type: ReserveType
    medicine_receive_type: number
    patient_id?: string
    payment_method?: number
    time_diff?: number
    reserve_limit?: number
  }
}

/**
 * 予約チェックのレスポンス
 */
export interface IValidateReserveDataResponse {
  isValid: boolean
  message: string
}

/**
 * 患者予約リクエスト（今すぐオンライン診療）
 */
export interface ICustomerNowReserveRequest {
  clinic: {
    department_id: string
    interviews: any[]
    interviews_for_pharmacy?: any[]
    type: ReserveType
    images?: string[]
    medicine_receive_type: number
    waiting_at: number
    pharmacy_id: string
    payment_method?: number
  }
}

/**
 * 患者予約変更リクエスト
 */
export interface IChangeReserveRequest {
  clinic: {
    id: string,
    medical_id: string,
    date: number,
    from: ReserveTimeType,
    doctor_id: string,
    department_id: string,
    medicine_receive_type: number,
    time_diff?: number
    reserve_limit?: number
  }
  pharmacy: {
    id: string,
    medical_id: string,
    date: number,
    from: ReserveTimeType,
    medicine_receive_type?: number,
    time_diff?: number
    reserve_limit?: number
  }
  forAdmin?: boolean
}

export interface ISendChangeReserveNotification {
  clinic_reserve_id: string
  pharmacy_reserve_id: string
  before_clinic_reserve: IReserve
  before_pharmacy_reserve: IReserve
}

/**
 * 予約
 */
export interface IReserve {
  id: string
  type: ReserveType
  medical_id: string
  doctor_id: string
  doctor_name: string
  status: ReserveStatusType
  patient_id: string
  patient_name: string
  gender: number
  age: number
  result?: {
    start_time?: number
    end_time?: number
    medical_fee?: number
    delivery_fee?: number
    system_fee?: number
    np_fee?: number
    other_fee?: number
    amount?: number
    charge_id?: string
    tracking_number?: string
    shopTransactionId?: string
    gmoTransactionId?: string
    shipping_company?: string
    slip_number?: string
  }
  interviews: any
  images?: any
  calling?: boolean
  waiting_at?: number
  search?: any
  first?: boolean
  department_id?: string
  pharmacy_id?: string
  date?: number
  from?: ReserveTimeType
  to?: DisplayTimeType
  created_at?: any
  updated_at?: any
  medicine_receive_type?: MedicineReceiveType
  express?: {
    id?: string
    ready_by?: string
    delivery_at?: string
    delivery_status?: number
    anchor_name?: string
    caminova?: boolean
    anchor_at?: {
      latitude?: number
      longitude?: number
    }
  }
  family_no?: any
  no_medicine: boolean
  payment_method?: number
  confirmed?: boolean
  has_prescription?: boolean
  shipping? : {
    request_time?: number
    request_result?: string
    request_id?: string
    shipping_number?: string
    shipping_company?: string
    download_url?: string
    binsyu_code?: string
    warning_code?: string
  }
  in_hospital_prescription?: boolean
  subscribed?: boolean
  order_id?: string[]
  stock_check?: boolean
  express_type?: number
  company_id? : string
  japanpost? : {
    request_time?: number
    result_code?: string
    tracking_number?: string
    download_url?: string
    result_detail?: string
  }
  pudo_shop?: {
    id: string
    address: string
    latitude: number
    longitude: number
    name: string
    post_code: string
    prefecture: string
  }
  select_pudo?: {
    ADR: string
    DCD: string
    F: string
    LOGO_KB: string
    NM: string
    SITE_ID: string
    TEL: string
    YTC: string
    ZIP: string
  }
  coupon?: string
  before_reserve? : any
  delivery_address?: IDeliveryAddress
  time_diff?: number
  reserve_limit?: number
  is_pet?: boolean
  pet_type?: string
  my_number_status?: number
}

/**
 * 機関タイプ
 */
export const INSTITUTION = {
  CLINIC: 0,
  PHARMACY: 1
} as const
export type InstitutionType = typeof INSTITUTION[keyof typeof INSTITUTION]

export interface IMedicalBase {
  id: string,
  store_id?: string,
  type: InstitutionType,
  name: string,
  today_reservable_count?: number
  tel: string,
  fax: string,
  url: string,
  tags: string[],
  rate?: number
  rate_count?: number
  post_code: string,
  prefecture: string,
  address1: string,
  address2: string,
  description: string,
  departments?: {
    id: string
    description: string
    doctors: string[],
    sort_no?: number,
    in_hospital_prescription?: boolean,
  }[]
  warning: string,
  main_image: string,
  main_image_thumbnail: string,
  start: ReserveTimeType,
  end: DisplayTimeType,
  day_of_week: any,
  images: string[],
  search: any,
  pharmacists?: string[]
  first_visit?: boolean
  re_visit?: boolean
  updated_at?: any
  created_at?: any
  holidays?: IHolidays
  sg_client_code?: string
  ec_corp_id?: string
  coordinates?: any
  caminova_id?: string
  and_buy?: boolean
  send_fax?: boolean
  reserve_limit?: number
}

/**
 * 日毎のスケジュール
 */
export interface IHolidays {
  [date: string] : {
    is_clinic : boolean,
    title : string
  }
}

export interface IDepartmentBase {
  id: string,
  name: string,
  type: InstitutionType,
  interview_sheet?: any,
  sort_no?: number
}

/**
 * メッセージ情報
 */
export interface IMessageDoc {
  id: string
  chats?: Array<IMessage>
  users: any
}
export interface IMessagePremiumDoc {
  id: string
  chats?: Array<IMessage>
  users: any
  name: string
  name_kana: string
  tel: string
  send_updated_at?: number
  receive_updated_at?: number
}

/**
 * 予約情報更新リクエスト
 */
export interface IUpdateReserveStatusRequest {
  reserveId: string
  status?: number
}

/**
 * 予約情報(メモ)更新リクエスト
 */
export interface IUpdateReserveMemoRequest {
  reserveId: string
  memo?: string
}

export interface ISetReserveDataToAurora {
  reserveId: string
  date?: number
  status?: ReserveStatusType
  shipment_date?: number
  payment_method?: PaymentMethod
  result?: ISetReserveResultDataToAurora
  delete_result?: boolean
}

export interface ISetReserveResultDataToAurora {
  amount?: number
  delivery_fee?: number
  discount_fee?: number
  medical_fee?: number
  other_fee?: number
  system_fee?: number
  np_fee?: number
  menu?: string[]
  set_count?: number
}

/**
 * 予約情報(追跡番号)更新リクエスト
 */
export interface IUpdateReserveTrackRequest {
  reserveId: string
  track_number?: string
}

/**
 * 予約情報更新リクエスト
 */
export interface ICancelPharmacyReserveRequest {
  pharmacyReserveId: string
  clinicReserveId: string
}

/**
 * 予約情報取得リクエスト
 */
export interface IGetReserveListRequest {
}

/**
 * 薬局スケジュール
 */
export interface IPharmacySchedule {
  [medical_id: string]: IPharmacyMonthSchedule
}

/**
 * 薬局の月ごとのスケジュール
 */
export interface IPharmacyMonthSchedule {
  [ym: string]: IPharmacyDaySchedule
}

/**
 * 薬局の日のスケジュール
 */
export interface IPharmacyDaySchedule {
  [day: string]: IPharmacistSchedule
}

/**
 * 薬剤師のスケジュール
 */
export interface IPharmacistSchedule {
  [pharmacist_id: string]: {
    [reserve_time: string]: boolean | string
  }
}

/**
 * シフト情報
 */
export interface IClinicSchedule {
  id: string
}

/**
 * シフト情報（クリニック）
 */
export interface IMedicalSchedule {
  [medical_id: string]: IYMSchedule
}

/**
 * シフト情報（年月）
 */
export interface IYMSchedule {
  [ym: string]: IDepartmentSchedule[]
}

/**
 * シフト情報（年月）
 */
export interface IDepartmentSchedule {
  id?: string | any

  [day: string]: {
    [doctor_id: string]: {
      [reserveTime: string]: boolean | string
    }
  }
}

/**
 * シフト情報更新用
 */
export interface IClinicScheduleRecord {
  medical_id: string,
  ym: string,
  departments?: string[]
}

/**
 * クリニック決済情報
 */
export interface ICaptureFuncValue {
  medical_fee: number,
  delivery_fee: number,
  system_fee: number,
  np_fee: number,
  other_fee: number,
  total_fee: number,
  tracking_number: string,
  shipping_company: string,
  slip_number: string,
}

/**
 * お急ぎ便用顧客オブジェクト
 */
export interface IExpressCustomer {
  last_name: string
  first_name: string
  business_name?: string
  phone: string
  email?: string
  address_zipcode?: string
  address_prefecture: string
  address_line_1: string
  address_line_2?: string
}

/**
 * お急ぎ便ドライバーオブジェクト
 */
export interface IExpressDriver {
  id: string
  name: string
  phone?: string
  vehicle_type?: string
  location: {
    longitude: number
    latitude: number
    update: string
  }
}

/**
 * お急ぎ便用配送オブジェクト
 */
export interface IExpressPackage {
  service_brand: string
  order_reference: string
  name: string
  price?: string
  number: number
  boxes: number
  height?: number
  width?: number
  depth?: number
  weight?: number
  attention?: string
}

/**
 * お急ぎ便時間枠オブジェクト
 */
export interface IExpressTimeWindow {
  id: string
  starts_at: string | null
  ends_at: string
  expires_at: string
}

/**
 * お急ぎ便ストアオブジェクト
 */
export interface IExpressStore {
  id: string
  id_alias: string
  store_name: string
  store_address_zipcode: string
  store_address_prefecture: string
  store_address_line_1: string
  store_address_line_2: string
  store_phone: string
  store_type: "standalone_store" | "in_mall_store" | "consolidation_store"
  offers_delivery: boolean
  offers_fetches: boolean
  pickup_proof: "barcode" | "signature" | "selfcheck"
  delivery_proof: string
}

/**
 * お急ぎ便ストアオブジェクト(リクエスト用)
 */
export interface IExpressStoreRequest {
  id_alias?: string
  store_name: string
  store_address_zipcode: string
  store_address_prefecture: string
  store_address_line_1: string
  store_address_line_2?: string
  store_phone: string
  store_type?: string
  pickup_proof?: string
  opening_time?: string
  closing_time?: string
}

/**
 * お急ぎ便（高知)ストアオブジェクト(リクエスト用)
 */
export interface ICaminovaStoreRequest {
  code?: string
  name?: string
  zipcode?: string
  address?: string
  tel?: string
  latitude?: string
  longitude?: string
}

/**
 * お急ぎ便配送見積もりリクエストオブジェクト
 */
export interface IExpressDeliveryEstimateRequest {
  store_id: string
  ready_by: string
  customer: {
    address_zipcode: string
    address_prefecture?: string
    address_line_1?: string
    address_line_2: string
  }
  packages: IExpressPackage[]
}

/**
 * お急ぎ便配送見積もりリクエストオブジェクト
 */
export interface ICaminovaDeliveryEstimateRequest {
  reserve_code: string
  s_pharmacy_code: string
  to_name: string
  to_address: string
  to_tel: string
  limit_date: string
  limit_time: string
  memo: string
}

/**
 * お急ぎ便配送見積もりレスポンスオブジェクト
 */
export interface IExpressDeliveryEstimateResponse {
  id: string
  object: string
  delivery_windows: IExpressTimeWindow[]
  unavailable_windows?: IExpressTimeWindow[]
  customer: {
    address_zipcode: string
    address_prefecture: string
    address_line_1: string
    address_line_2: string
  }
  store: {
    store_id: string
    store_name: string
    store_address_prefecture: string
    store_address_line_1: string
    store_address_line_2: string
    store_phone: string
    store_type: string
  }
  distance: number
  packages: IExpressPackage[]
}

/**
 * お急ぎ便配送見積もりレスポンスオブジェクト
 */
export interface ICaminovaDeliveryEstimateResponse {
  message: string
  reserve_fg: string
  call_fg: string
  contract_fg: string
}

/**
 * お急ぎ便配送依頼リクエストオブジェクト
 */
export interface IExpressDeliveryRequest {
  store_id: string
  ready_by: string
  delivery_window_id: string
  customer: IExpressCustomer
  packages: IExpressPackage[]
  pickup_comments: string
  destination_comments: string
  until?: string
}

/**
 * お急ぎ便配送依頼リクエストオブジェクト
 */
export interface ICaminovaDeliveryRequest {
  reserve_code: string
}

/**
 * お急ぎ便配送依頼レスポンスオブジェクト
 */
export interface IExpressDeliveryResponse {
  id: string
  object: string
  tracking_code: string
  status: string
  ready_by: string
  driver: null | IExpressDriver
  delivered_at: null | string
  delivery_window: IExpressTimeWindow
  customer: IExpressCustomer
  store: {
    store_id: string
    store_name: string
    store_address_prefecture: string
    store_address_line_1: string
    store_address_line_2: string
    store_phone: string
    store_type: string
  }
  pickup_comments: string
  destination_comments: string
  store_signature: string
  customer_signature: string
  packages: IExpressPackage[]
  distance: number
  etc_price: string
  etc_price_reason: string
  exception: null | any
  update: string
}

/**
 * お急ぎ便配送依頼レスポンスオブジェクト
 */
export interface ICaminovaDeliveryResponse {
  message: string
  call_fg: string
  contract_fg: string
}

/**
 * 予約情報更新リクエスト
 */
export interface IIsReservableDepartments {
  medicalId: string
  departmentId: string
}

/**
 * 患者予約リクエスト
 */
export interface IProxyReserveRequest {
  clinic: {
    date: number
    department_id: string
    doctor_id: string
    first: boolean
    from: ReserveTimeType
    interviews: any[]
    medical_id: string
    type: ReserveType
    images?: string[]
    medicine_receive_type: number
    in_hospital_prescription: boolean
    payment_method?: number
    family_no?:any
    delivery_address?: IDeliveryAddress
    time_diff?: number
    reserve_limit?: number
    is_pet?: boolean
    pet_type?: string
    my_number_status?: number
  }
  pharmacy: {
    date: number
    doctor_id: string
    from: ReserveTimeType
    interviews: any[]
    medical_id: string
    type: ReserveType
    medicine_receive_type: number
    patient_id?: string
    payment_method?: number
    family_no?:any
    delivery_address?: IDeliveryAddress
    time_diff?: number
    reserve_limit?: number
    is_pet?: boolean
    pet_type?: string
    my_number_status?: number
  }
  patient_id: string
  no_express?: boolean
}

/**
 * 予約カウント
 */
export interface IMedicalCount {
  id: string,
  EMPTY?: number,
  RESERVE?: number,
  WAITING?: number,
  DOCTOR_WAITING?: number,
  START?: number,
  END?: number,
  EXIT_ROOM?: number,
  CANCEL_BY_CUSTOMER?: number,
  CANCEL_BY_DOCTOR?: number,
  CANCEL_BY_SYSTEM?: number,
  PAID?: number,
  FAILED?: number
}

export interface ICheck {
  device_id: string
}

export interface IDistances {
  from: string[]
  to: string[]
}

export interface IExecTransactionRequest {
  request: {
    shopInfo: {
      authenticationId: string,
      shopCode: string,
      connectPassword: string,
    },
    httpInfo: {
      httpHeader: string,
      deviceInfo: string,
    },
    buyer: {
      shopTransactionId: string,
      shopOrderDate: string,
      fullName?: string,
      fullKanaName?: string,
      zipCode?: string,
      address?: string,
      tel1?: string,
      email1: string,
      billedAmount?: number,
      paymentType: string,
      sex?: number,
      birthday?: string,
      memberRegistDate?: string,
    },
    deliveries: {
      delivery?: {
        deliveryCustomer?: {
          fullName?: string,
          fullKanaName?: string,
          zipCode?: string,
          address?: string,
          tel?: string,
        },
        details: {
          detail: {
            detailName: string,
            detailPrice: number,
            detailQuantity: string,
            detailBrand: string,
          }
        }
      }
    }
  }
}

export interface IExecTransactionResponse {
  result?: boolean,
  shopTransactionId?: string
  gmoTransactionId?: string
}

export interface IExecCancelTransactionRequest {
  request: {
    shopInfo: {
      authenticationId: string,
      shopCode: string,
      connectPassword: string,
    },
    kindInfo: {
      updateKind: string,
    },
    buyer: {
      gmoTransactionId: string,
    },
  }
}

export interface IExecCancelPdrequestRequest {
  request: {
    shopInfo: {
      authenticationId: string,
      shopCode: string,
      connectPassword: string,
    },
    kindInfo: {
      updateKind: string,
    },
    transaction: {
      gmoTransactionId: string,
    },
  }
}
export interface IExecModifyTransactionRequest {
  request: {
    shopInfo: {
      authenticationId: string,
      shopCode: string,
      connectPassword: string,
    },
    kindInfo: {
      updateKind: string,
    },
    buyer: {
      gmoTransactionId?: string,
      shopTransactionId: string,
      shopOrderDate: string,
      fullName?: string,
      fullKanaName?: string,
      zipCode?: string,
      address?: string,
      tel1?: string,
      email1: string,
      billedAmount?: number,
      paymentType: string,
      sex?: number,
      birthday?: string,
      memberRegistDate?: string,
    },
    deliveries: {
      delivery?: {
        deliveryCustomer?: {
          fullName?: string,
          fullKanaName?: string,
          zipCode?: string,
          address?: string,
          tel?: string,
        },
        details: {
          detail: {
            detailName: string,
            detailPrice: number,
            detailQuantity: string,
            detailBrand: string,
          }
        }
      }
    }
  }
}

export interface IPdRequest {
  request: {
    shopInfo: {
      authenticationId: string,
      shopCode: string,
      connectPassword: string,
    },
    transaction: {
      gmoTransactionId?: string,
      pdcompanycode?: string,
      slipno?: string,
      invoiceIssueDate?: string
    },
  }
}

export interface IReserveDoctorSort {
  [doctor_id: string]: {
    id: string
    count: number
  }
}
export interface IReservePatientSort {
  [patient_id: string]: {
    id: string
    count: number
  }
}

export interface IInvoiceRequest {
  id: string
  reserve_id: string
  invoice_request_id: string
}

export interface IShippingRequest {
  shippingRequest: {
    customerAuth: {
      customerId: string
      loginPassword: string
    }
    printOutFlg: number
    okuriCode: string
    outputLevel: string
    backLayerFlg: number
    printDataList: {
      printDataDetail: {
        haisoKosu?: number
        userManageNumber?: string
        kokyakuCode?: string
        otodokeAdd1?: string
        otodokeAdd2?: string
        otodokeAdd3?: string
        otodokeNm1?: string
        otodokeNm2?: string
        otodokeYubin?: string
        otodokeTel?: string
        iraiPrintFlg?: number
        iraiAdd1?: string
        iraiAdd2?: string
        iraiAdd3?: string
        iraiNm1?: string
        iraiNm2?: string
        iraiYubin?: string
        iraiTel?: string
        kiji1?: string
        kiji2?: string
        binsyuCode?: string
        daibikiFlg?: number
        shukaIraiData?: {
          shukaIraiCode?: string
          shukaIraiShiteiDate?: string
          shukaIraiShiteiTimeCode?: string
        }
      }
    }
  }
}

export interface IShippingResponse {
  result_code: string
  request_id?: string
  shipping_number?: string
  sg_client_code?: string
  warning_code?:string
}

export interface IShippingFileCheckRequest {
  checkFileRequest: {
    customerAuth: {
      customerId: string
      loginPassword: string
    }
    printRequestIdList: {
      printRequestId: string
    }
  }
}

export interface IShippingFileCheckResponse {
  result_code: string
  download_url?: string
}

export interface IShippingFileRequest {
  id: string
  reserve_id: string
  request_id: string
  sg_client_code: string
  result_code?: string
}

export interface IShippingFileCheckRequest {
  checkFileRequest: {
    customerAuth: {
      customerId: string
      loginPassword: string
    }
    printRequestIdList: {
      printRequestId: string
    }
  }
}

export interface ICorp {
  id: string
  name: string,
  restrict: string[]
}

/**
 * SAGAWA カスタマー情報
 */
export interface ISGCustomerInfo {
  id: string
  customer_id: string
  password: string
}

export interface IJoinSubscriptionRequest {
  subscription_plan: SubscriptionPlanType
}
export interface ICreateSubscriptionRequest {
  subscription_plan: SubscriptionPlanType
  private_id: string
  first_subs?: boolean
}

export interface IUpdateSubscriptionPlanRequest {
  new_subscription_plan: SubscriptionPlanType
  private_id: string
}

export interface ICancelSubscriptionRequest {
}

export interface IGetSubscriptionStatusRequest {
}

interface  ISG_ERR_CODE {
  [key: string]: string
}
export const SG_ERR_CODE: ISG_ERR_CODE = {
  "E1-0001": "必須項目に値がありません",
  "E1-0002": "管理番号が重複しています",
  "E1-0003": "未登録の顧客コードです",
  "E1-0004": "個口数が正しくありません",
  "E1-0005": "便種コードが正しくありません",
  "E1-0006": "配達時間指定が正しくないコードです",
  "E1-0007": "対象の顧客コードは代引契約をしていません",
  "E1-0008": "配達指定日の日付が正しくありません",
  "E1-0009": "発送日の日付が正しくありません",
  "E1-0010": "シール１のコードが正しくありません",
  "E1-0011": "シール２のコードが正しくありません",
  "E1-0012": "シール３のコードが正しくありません",
  "E1-0013": "代金引換が正しくありません",
  "E1-0014": "対象の顧客コードは運用保険を利用できません",
  "E1-0015": "依頼主住所１～３で文字数をオーバーしています",
  "E1-0016": "依頼主氏名１～２で文字数をオーバーしています",
  "E1-0017": "依頼主住所１に値がありません",
  "E1-0018": "依頼主氏名１に値がありません",
  "E1-0019": "お届け先住所１に値がありません",
  "E1-0020": "お届け先氏名１に値がありません",
  "E1-0021": "記事１～６の文字数がオーバーしています",
  "E1-0022": "フラグの値が正しくありません",
  "E1-0023": "重量１または重量２のコードが正しくありません",
  "E1-0024": "発行受付IDが重複しています",
  "E1-0025": "該当する発行受付IDがありません",
  "E1-0026": "該当する問合番号がありません",
  "E1-0027": "元着コードが正しくありません",
  "E1-0028": "配送料金が着払の場合は代引が利用できません。",
  "E1-0029": "配達指定日が過去日付です",
  "E1-0030": "再発行期間対象外です",
  "E1-0032": "代引支払方法区分が正しくありません",
  "E1-0035": "文字数が正しくありません",
  "E1-0036": "同じシールは２つ指定できません",
  "E1-0037": "配達指定日が30日を超える未来日付です",
  "E1-0038": "届先郵便番号が正しい値ではありません",
  "E1-0039": "依頼主郵便番号が正しい値ではありません",
  "E1-0040": "届先電話番号が正しい値ではありません",
  "E1-0041": "依頼主電話番号が正しい値ではありません",
  "E1-0042": "お届先住所１～３で文字数をオーバーしています",
  "E1-0043": "お届先氏名１～２で文字数をオーバーしています",
  "E1-0044": "営業所止めでの時間帯指定は不可となります",
  "E1-0045": "営業所止めでの配達指定は不可となります",
  "E1-0046": "発送日が30日を超える未来日付です",
  "E1-0047": "送り状コードが正しくありません",
  "E1-0048": "出力レベルが正しくありません",
  "E1-0049": "管理番号が正しくありません",
  "E1-0050": "顧客コードが正しくありません",
  "E1-0051": "依頼主メールアドレスが正しくありません",
  "E1-0052": "お届けメールアドレスが正しくありません",
  "E1-0053": "代引消費税が正しくありません",
  "E1-0054": "保険金額が正しくありません",
  "E1-0055": "営業所コードが正しくありません",
  "E1-0056": "納品書情報、商品リストにて数値ではない値があります",
  "E1-0057": "代引支払方法区分は対応できません",
  "E1-0058": "発送日が過去日付です",
  "E1-0059": "再印刷する問合番号が重複しています",
  "E1-0060": "指定期間が正しくありません",
  "E1-0061": "郵便番号または住所に値がありません",
  "E1-0062": "数値が正しくありません",
  "E1-0063": "出荷日が正しくありません",
  "E1-0064": "配送会社コードが正しくありません",
  "E1-0065": "受取コードが正しくありません",
  "E1-0066": "対象の顧客コードは受取コードの契約をしていません",
  "E1-0067": "受取サービス利用での営業所受取サービスの利用はできません",
  "E1-0068": "受取サービス利用での配達指定サービスの利用はできません",
  "E1-0069": "受取サービス利用での時間帯指定サービスの利用はできません",
  "E1-0070": "受取サービス利用での代金引換サービスの利用はできません",
  "E1-0071": "受取サービス利用での複数個口の利用はできません",
  "E1-0072": "受取サービス利用での指定された便種の利用はできません",
  "E1-0073": "受取店舗コードが正しくありません",
  "E1-0074": "受取受注番号が正しくありません",
  "E1-0075": "受取人氏名１～２で文字数をオーバーしています",
  "E1-0076": "返送先住所１～３で文字数をオーバーしています",
  "E1-0077": "返送先氏名１～２で文字数をオーバーしています",
  "E1-0078": "返送先住所１に値がありません",
  "E1-0079": "返送先氏名１に値がありません",
  "E1-0080": "返送先郵便番号が正しい値ではありません",
  "E1-0081": "返送先電話番号が正しい値ではありません",
  "E1-0082": "返送先郵便番号に値がありません",
  "E1-0083": "返送先電話番号に値がありません",
  "E1-0084": "帳票コードが正しくありません",
  "E1-0085": "荷物受渡書・出荷明細出力タイプが正しくありません",
  "E1-0086": "利用フラグが正しくありません",
  "E1-0087": "集荷依頼主コードが正しくありません",
  "E1-0088": "集荷依頼時間指定コードが正しくありません",
  "E1-0089": "集荷依頼指定日が正しくありません",
  "E1-0090": "集荷依頼指定日が過去日付です",
  "E1-0091": "集荷依頼指定日がが30日を超える未来日付です",
  "E1-0092": "利用する出荷情報を選択してください",
  "E1-0093": "対象の顧客コードは集荷依頼契約をしていません",
  "E1-0094": "対象の送り状コードでの受取サービスの利用はできません",
  "E1-0095": "対象の送り状コードでの着払いの利用はできません",
  "E1-0096": "対象の送り状コードでの集荷依頼の利用はできません",
  "E1-0097": "認証番号が正しくありません",
  "E1-0098": "認証番号に値がありません",
  "E2-0001": "郵便番号と住所が正しくありません",
  "E2-0002": "郵便番号から該当する住所はありません",
  "E2-0003": "住所から該当する郵便番号はありません",
  "E2-0004": "お届け先の郵便番号と住所が一致していません",
  "E2-0005": "依頼主の郵便番号と住所が一致していません",
  "E2-0006": "対象の発行受付IDはまだ発行の準備ができていません",
  "E2-0007": "代金引換不可エリアです",
  "E2-0008": "クール冷蔵不可エリアです",
  "E2-0009": "クール冷凍不可エリアです",
  "E2-0010": "有効期限外のデータです",
  "E2-0011": "帳票種類が異なる送り状を同時に再印刷リクエストする事はできません",
  "E2-0013": "データ取得有効期間を過ぎています",
  "E2-0014": "リクエストできるレコード数をオーバーしています",
  "E2-0015": "返送先の郵便番号と住所が一致していません",
  "W2-0001": "時間帯指定不可エリアです",
  "W2-0003": "中継エリアです。追加費用が発生するためSOKUYAKUサポートへご連絡後、決済ください。",
  "W2-0005": "取得できなかった明細データがあります",
  "E3-0001": "アカウント認証が正しくありません",
  "E8-0001": "エラー有り",
  "E8-0002": "問合番号が取得できません",
  "E8-0003": "問合番号が取得できません",
  "E9-0001": "システムエラーです、管理者へお問い合わせください",
} as const
export type SGErrCodeType = typeof SG_ERR_CODE[keyof typeof SG_ERR_CODE]

/**
 * お急ぎ便ストアオブジェクト(リクエスト用)
 */
export interface IFax_request {
  sendto: faxNoType[]
  userkey?: string
  tsi?: string
  headerinfo?: string
  retrynum?: string
  resaddress?: string
  subject: string
  body?: string
  Attachment?: Array<IAttachment>
}

// 添付ファイルの定義例
export interface IAttachment {
  attachmentdata: string; // Base64エンコードしたファイル本体
  attachmentname: string;       // ファイル名
}

interface faxNoType {faxno: string}

export const RESERVE_FILTER_CHANGE = [
  {
    title: "予約済み",
    value: RESERVE_STATUS.RESERVE,
  },
  {
    title: "診察終了",
    value: RESERVE_STATUS.END,
  },
  {
    title: "利用者キャンセル",
    value: RESERVE_STATUS.CANCEL_BY_CUSTOMER,
  },
  {
    title: "医療機関キャンセル",
    value: RESERVE_STATUS.CANCEL_BY_DOCTOR,
  },
  {
    title: "システムキャンセル",
    value: RESERVE_STATUS.CANCEL_BY_SYSTEM,
  },
  {
    title: "支払い済み",
    value: RESERVE_STATUS.PAID,
  },
  {
    title: "支払い失敗",
    value: RESERVE_STATUS.FAILED,
  },
]

export const RESERVE_FILTER = [
  {
    title: "空",
    value: RESERVE_STATUS.EMPTY,
  },
  {
    title: "予約済み",
    value: RESERVE_STATUS.RESERVE,
  },
  {
    title: "待合室待機中",
    value: RESERVE_STATUS.WAITING,
  },
  {
    title: "医師待機中",
    value: RESERVE_STATUS.DOCTOR_WAITING,
  },
  {
    title: "開始",
    value: RESERVE_STATUS.START,
  },
  {
    title: "診察終了",
    value: RESERVE_STATUS.END,
  },
  {
    title: "待合室から退室",
    value: RESERVE_STATUS.EXIT_ROOM,
  },
  {
    title: "利用者キャンセル",
    value: RESERVE_STATUS.CANCEL_BY_CUSTOMER,
  },
  {
    title: "医療機関キャンセル",
    value: RESERVE_STATUS.CANCEL_BY_DOCTOR,
  },
  {
    title: "システムキャンセル",
    value: RESERVE_STATUS.CANCEL_BY_SYSTEM,
  },
  {
    title: "支払い済み",
    value: RESERVE_STATUS.PAID,
  },
  {
    title: "支払い失敗",
    value: RESERVE_STATUS.FAILED,
  },
]

/**
 * 問診タイプ
 */
export const INTERVIEW_ANSWER_TYPE = {
  TEXT: 0,
  SELECT: 1,
  CHECK: 2,
} as const
export type InterviewAnswerType = (typeof INTERVIEW_ANSWER_TYPE)[keyof typeof INTERVIEW_ANSWER_TYPE]

export interface IInterview {
  question: string
  required: boolean
  answer_type: InterviewAnswerType
  answer_data: string[]
  result?: number | boolean[] | string | null
}

export interface ICheckMedicineStockAPI {
  reserve_id: string
}

export interface IExpress {
  id: string,
  name: string,
  sort_no: number,
  post_number?: string[],
  other?: {
    caminova?: string[],
    pickgo?: string[],
    postal?: string[],
  }
}

export interface IDeliveryCompany {
  post_code?: string
  prefecture?: string
}

export const EXPRESS = {
    DIAQ: {
        type: 0,
        name: "Diaq",
        key: "diaq",
    },
    POSTAL: {
        type: 1,
        name: "日本宅配便協会",
        key: "postal",
    },
    PICKGO: {
        type: 2,
        name: "PickGo",
        key: "pickgo",
    },
    CAMINOVA: {
        type: 3,
        name: "カミノバ",
        key: "caminova",
    },
    NONE: {
      type: 99,
      name: "連携なし",
      key: "none",
    }
}
export type ExpressType = (typeof EXPRESS)[keyof typeof EXPRESS]

export interface ICustomAuthAuroraRequest {
  email: string;
  password: string;
}

export interface ISearchMedicalByAlgolia {
  type: number,
  search?: string,
  department?: string,
  prefecture?: string,
  rate_from?: number,
  rate_to?: number,
  first_visit?: boolean,
  geo?: any,
  page?: number,
  and_buy?: boolean,
  visit?: boolean,
  today?: boolean,
  per_page?: number,
  japanpost?: boolean,
  available?: number,
  is_ec?: boolean,
  sunday?: boolean
}

export interface GoodsItem {
  itemName: string
  damagePayment?: string // オプショナルにする場合は?を使用
  customerEntryColumn1?: string
  customerEntryColumn2?: string
}

export interface IJapanPostLabelRequestParams {
  userID: string
  messageVersion: string
  messageSenderID: string
  operationCode: string
  consignorID: string
  labelKind: string
  invoice: {
    transportInstructionID: string
    jpParcelServiceCode: string
    attentionCode: string
    packageTotalNum: string
    customerDedicatedNo?: string
    consignor: {
      consignorName: string
      consignorTel: string
      consignorPostNo: string
      consignorAddress: string
    }
    destination: {
      destName: string
      destTel: string
      destPostNo: string
      destAddress: string
    }
    goodsItems: GoodsItem[]
  }
}

export interface IJapanPostShippingResponse {
  downloadUrl: string
  TrackingNo: string
  ResultCode: string
  ResultDetail: string
}

export interface IPudo {
  id: string
  name: string
  post_code: string
  prefecture: string
  address: string
  latitude: number
  longitude: number
}

// リクエストデータの型定義
export interface IEmailRequestData {
  patient_ids: string[]
}

// レスポンスデータの型定義
export interface IAuthUser {
  id: string
  email?: string
}

export interface ISetSelectPudoSetting {
  reserve_id: string
  ADR: string
  DCD: string
  F: string
  LOGO_KB: string
  NM: string
  SITE_ID: string
  TEL: string
  YTC: string
  ZIP: string
}

export interface ICouponGroup {
  id: string
  name: string
  description?: string
  from?: number
  to?: number
  type?: number
}

export const COUPON_TYPE = {
  UNLIMITED_ALL: {
    type: 1,
    name: "クリニック、薬局のシステム利用料無料（何度でも）",
  },
  UNLIMITED_CLINIC: {
    type: 2,
    name: "クリニックのシステム利用料無料（何度でも）",
  },
  UNLIMITED_PHARMACY: {
    type: 3,
    name: "薬局のシステム利用料無料（何度でも）",
  },
  SINGLE_ALL: {
    type: 11,
    name: "クリニック、薬局のシステム利用料無料（一回きり）",
  },
  SINGLE_CLINIC: {
    type: 12,
    name: "クリニックのシステム利用料無料（一回きり）",
  },
  SINGLE_PHARMACY: {
    type: 13,
    name: "薬局のシステム利用料無料（一回きり）",
  },
  UNLIMITED_DELIVERY_ALL: {
    type: 21,
    name: "クリニック、薬局の送料無料（何度でも）",
  },
  UNLIMITED_DELIVERY_CLINIC: {
    type: 22,
    name: "クリニックの送料無料（何度でも）",
  },
  UNLIMITED_DELIVERY_PHARMACY: {
    type: 23,
    name: "薬局の送料無料（何度でも）",
  },
  SINGLE_DELIVERY_ALL: {
    type: 31,
    name: "クリニック、薬局の送料無料（一回きり）",
  },
  SINGLE_DELIVERY_CLINIC: {
    type: 32,
    name: "クリニックの送料無料（一回きり）",
  },
  SINGLE_DELIVERY_PHARMACY: {
    type: 33,
    name: "薬局の送料無料（一回きり）",
  },
  USED_ONCE_PAR_USER: {
    type: 41,
    name: "ユーザー毎にクリニック、薬局でそれぞれ一回無料",
  },
  USED_ONCE_DELIVERY_PAR_USER: {
    type: 51,
    name: "ユーザー毎にお薬の送料が1回無料",
  },
}
export type CouponType = (typeof COUPON_TYPE)[keyof typeof COUPON_TYPE]
// typeを受け取ってnameを返す関数
export function getCouponNameByType(couponType: number): string | undefined {
  const typeKeys = Object.keys(COUPON_TYPE) as Array<keyof typeof COUPON_TYPE>
  for (const key of typeKeys) {
    if (COUPON_TYPE[key].type === couponType) {
      return COUPON_TYPE[key].name
    }
  }
  return undefined // 該当するtypeがない場合はundefinedを返す
}

export interface ICoupon {
  id: string
  used: boolean
  created_at?: any
  updated_at?: any
}

export const CLINIC_TARGET_COUPON = [
  COUPON_TYPE.UNLIMITED_ALL.type,
  COUPON_TYPE.UNLIMITED_CLINIC.type,
  COUPON_TYPE.SINGLE_ALL.type,
  COUPON_TYPE.SINGLE_CLINIC.type,
  COUPON_TYPE.UNLIMITED_DELIVERY_ALL.type,
  COUPON_TYPE.UNLIMITED_DELIVERY_CLINIC.type,
  COUPON_TYPE.SINGLE_DELIVERY_ALL.type,
  COUPON_TYPE.SINGLE_DELIVERY_CLINIC.type,
  COUPON_TYPE.USED_ONCE_PAR_USER.type,
  COUPON_TYPE.USED_ONCE_DELIVERY_PAR_USER.type,
]
export const PHARMACY_TARGET_COUPON = [
  COUPON_TYPE.UNLIMITED_ALL.type,
  COUPON_TYPE.UNLIMITED_PHARMACY.type,
  COUPON_TYPE.SINGLE_ALL.type,
  COUPON_TYPE.SINGLE_PHARMACY.type,
  COUPON_TYPE.UNLIMITED_DELIVERY_ALL.type,
  COUPON_TYPE.UNLIMITED_DELIVERY_PHARMACY.type,
  COUPON_TYPE.SINGLE_DELIVERY_ALL.type,
  COUPON_TYPE.SINGLE_DELIVERY_PHARMACY.type,
  COUPON_TYPE.USED_ONCE_PAR_USER.type,
  COUPON_TYPE.USED_ONCE_DELIVERY_PAR_USER.type,
]

export const SINGLE_COUPON = [
  COUPON_TYPE.SINGLE_ALL.type,
  COUPON_TYPE.SINGLE_CLINIC.type,
  COUPON_TYPE.SINGLE_PHARMACY.type,
  COUPON_TYPE.SINGLE_DELIVERY_ALL.type,
  COUPON_TYPE.SINGLE_DELIVERY_CLINIC.type,
  COUPON_TYPE.SINGLE_DELIVERY_PHARMACY.type,
]

export const SYSTEM_COUPON = [
  COUPON_TYPE.SINGLE_ALL.type,
  COUPON_TYPE.SINGLE_CLINIC.type,
  COUPON_TYPE.SINGLE_PHARMACY.type,
  COUPON_TYPE.UNLIMITED_ALL.type,
  COUPON_TYPE.UNLIMITED_CLINIC.type,
  COUPON_TYPE.UNLIMITED_PHARMACY.type,
  COUPON_TYPE.USED_ONCE_PAR_USER.type,
]

export const DELIVERY_COUPON = [
  COUPON_TYPE.UNLIMITED_DELIVERY_ALL.type,
  COUPON_TYPE.UNLIMITED_DELIVERY_CLINIC.type,
  COUPON_TYPE.UNLIMITED_DELIVERY_PHARMACY.type,
  COUPON_TYPE.SINGLE_DELIVERY_ALL.type,
  COUPON_TYPE.SINGLE_DELIVERY_CLINIC.type,
  COUPON_TYPE.SINGLE_DELIVERY_PHARMACY.type,
  COUPON_TYPE.USED_ONCE_DELIVERY_PAR_USER.type,
]

/*医療法人せせらぎ会　浮田クリニック
  医療法人社団蛍晶会　晴和ハートクリニック
  金沢メディカルステーション ヴィーク
  みのる内科クリニック
  上天草市立上天草総合病院
  井口腎泌尿器科・内科　親水
  ナゴヤガーデンクリニック
  ねもと皮フ科・形成外科
  医療法人 曙光会 おおつかレディースクリニック
  平野医院
  ★こちらは予約できません。SOKUYAKUクリニック（サンプル/決済＆付け替え）
  さくら耳鼻咽喉科
  いけだ内科
  御苑アンジェリカクリニック
  うつみ内科クリニック
  大空クリニック
  岩本医院
  ナゴヤガーデンクリニック
  古賀病院21
  岩本医院（岩国市）
  輝齢ハラダクリニック
  まきのファミリークリニック
  表参道ソフィアクリニック
  山口医院
  かわなかクリニック
  古賀総合病院
  百瀬医院
  医療法人社団　五稜会病院
  南部町国民健康保険　西伯病院
  田中ファミリークリニック
  広島ステーションクリニック
  共和病院
  医療法人社団春陽会　竹内耳鼻咽喉科
  まつむらファミリークリニック
  元町マリン眼科
  ひさい脳神経外科クリニック
  */
export const SEARCH_TARGET_MEDICAL_IDS = [
  "494qiz3g0lVPw2ZCa1g0",
  "4myeZPLoJhFwVbl9PKYQ",
  "elrBHDdu9eeURe9PdBFq",
  "f58UbpiIOo54j5UazZwB",
  "kpoCwtvZzrWLyLCGggof",
  "mbpWuMsTL9TVh8KazZNr",
  "nXoOGIdtifXPtngyVvBL",
  "zxUQva4hx7SGOunJPI28",
  "bjmD6duetp2he6lIDYSf",
  "uChid6mlkqd0aaI0RPeh",
  "jZKnjeOadpTs8P2JRNDY",
  "Nk1doXc70RJYaAHRKKCW",
  "lBEbgH5DwaSVxbsEeQFR",
  "yiUgZ6JzIMnU6nnuQyaC",
  "QiuPv8liBa3b9NqQkb8s",
  "8bZgWEOXNGhV9GRnUBhH",
  "8jynbKM20M8dWGFp1qgr",
  "QiuPv8liBa3b9NqQkb8s",
  "hE43USxjICxdt3Hjdbtr",
  "nXoOGIdtifXPtngyVvBL",
  "vXIaTjcdlQZoARhXupUB",
  "AX2Fp2EIMh4pJw5TOazD",
  "duptNTnZcsXMLkPyemH7",
  "U9p59ZgpwTayUqWYi0c9",
  "ypA0fAZSJb2diK7KiCG6",
  "1sRHcPyb9vbTseNHuWoa",
  "Ke4QkXgm0v6A3AnGbgZ6",
  "ZRSH4PcbGIIpbQuGoGE0",
  "pYAMEIQldWuWDfYPNtqx",
  "NiUMbZDezFigCULRysBn",
  "W1i4eaIlTpzHwFyrkvVT",
  "e1IvvbpYEAhQRwjsAkus",
  "gitJUJcb4M5O7hzELQy2",
  "61PpiDJvAmDqD4HQyd4k",
  "m9yj2oMyvq8lXSVxDCfv",
  "DJY3J5yURGF2HCZHtUjJ",
  "fzx9Zc1olYTp7lK03JpP",
  "cJIbKVgmxRZNgZqkdjke"
]

export const IGNORE_NOTIFICATION_MEDICAL_IDS = [
  "Nk1doXc70RJYaAHRKKCW",
  "TZsTMRmoRZISaThrE1XV",
]

export interface IReplacePharmacyReserve {
  pharmacy_id: string
  reserve_id: string
  medicine_receive_type: MedicineReceiveType
  date: number
  from: number
  time_diff?: number
}

export const CANCEL_STATUS_LIST = [
  RESERVE_STATUS.CANCEL_BY_CUSTOMER,
  RESERVE_STATUS.CANCEL_BY_DOCTOR,
  RESERVE_STATUS.CANCEL_BY_SYSTEM
]

export interface ISetReserveDeliveryAddress {
  reserveId: string
  result: IDeliveryAddress
}

export interface IFriendCoupon {
  id: string,
  code?: string,
  user_clinic?: IFriendCouponUser[],
  user_pharmacy?: IFriendCouponUser[],
  created_at?: any
  updated_at?: any
}

export interface IFriendCouponUser {
  user_id: string,
  reserve_id: string
  used_at: number,
}

export interface IReservationAlgolia {
  date: string;
  total: number;
  department_id?: string;
}

export interface IDepartmentAlgolia {
  department_id: string;
  reservations: IReservationAlgolia[];
}

export interface IMedicalAlgolia {
  id: string; // AlgoliaのオブジェクトIDとして使用
  departments: IDepartmentAlgolia[];
}
